import { ICompany } from './company';
import { v4 as uuidv4 } from 'uuid';

export interface IStore {
    id: string;
    createdAt: Date;
    createdBy: string;
    name: string;
    zipCode: string;
    town: string;
    address: string;
    phoneNumber: string;
    email: string;
    businessHours: string;
    latitude: number;
    longitude: number;
    isActive: boolean;
    isEnabledByDivian: boolean;
    isLocationCalculated: boolean;
    venCode: string;
    lastModifiedAt: string;
    accountNumber: string;
    beneficiaryName: string;
    region: IRegion;
    companies: ICompany[];
    shops: IShop[];
    isActiveOnMap: boolean;
    regionDeliveryPrices: RegionDeliveryPrice[];
}

export class Store implements IStore {
    id!: string;
    createdAt!: Date;
    createdBy!: string;
    name!: string;
    zipCode!: string;
    town!: string;
    address!: string;
    phoneNumber!: string;
    email!: string;
    businessHours!: string;
    latitude!: number;
    longitude!: number;
    isActive!: boolean;
    isEnabledByDivian!: boolean;
    isLocationCalculated!: boolean;
    venCode!: string;
    lastModifiedAt!: string;
    accountNumber: string;
    beneficiaryName: string;
    region!: IRegion;
    companies: ICompany[] = [];
    shops: IShop[] = [];
    isActiveOnMap: boolean;
    regionDeliveryPrices: RegionDeliveryPrice[];
}

export interface IShop {
    id: string;
    isShowableOnMap: boolean;
    isOfferable: boolean;
    name: string;
    zipCode: string;
    town: string;
    address: string;
    phoneNumber: string;
    emailForCommunication: string;
    emailForMap: string;
    businessHours: string;
    latitude: number;
    longitude: number;
    region: IRegion;
}

export class Shop implements IShop {
    id!: string;
    isShowableOnMap!: boolean;
    isOfferable: boolean;
    name!: string;
    zipCode!: string;
    town!: string;
    address!: string;
    phoneNumber!: string;
    emailForCommunication!: string;
    emailForMap!: string;
    businessHours!: string;
    latitude!: number;
    longitude!: number;
    region!: IRegion;
}

export interface IRegion {
    id: string;
    name: string;
    nameId: string;
    latitude: number;
    longitude: number;
}

export class Region implements IRegion {
    id!: string;
    name: string;
    nameId: string;
    latitude: number;
    longitude: number;

    constructor(nameId?: string, name?: string, latitude?: number, longitude?: number) {
        this.nameId = nameId;
        this.name = name;
        this.latitude = latitude;
        this.longitude = longitude;
    }
}

export interface ILocation {
    latitude: number;
    longitude: number;
}

export class Location implements ILocation {
    latitude!: number;
    longitude!: number;
}

export class RegionDeliveryPrice {
    id: string;
    regionId: string;
    lowerLimit: number;
    upperLimit: number;
    price: number;

    constructor(regionId: string, lowerLimit: number, upperLimit: number, price: number) {
        this.id = uuidv4();
        this.regionId = regionId;
        this.lowerLimit = lowerLimit;
        this.upperLimit = upperLimit;
        this.price = price;
    }
}
