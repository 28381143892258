import { observable, action, makeObservable, computed } from 'mobx';
import agent from 'api/agent';
import { RootStore } from 'mobx/rootStore';
import { IUser, User } from 'models/user';
import { msalInstance } from 'config/authConfig';
import { NavigateFunction } from 'react-router-dom';

export default class UserStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable currentUser: IUser | null = null;

    @computed get isAdminUserLoggedIn(): boolean {
        const accounts = msalInstance.getAllAccounts();

        if (accounts.length === 0) {
            return false;
        }

        const signedInUser = accounts[0];

        return signedInUser && this.currentUser && this.currentUser.id === signedInUser.localAccountId;
    }

    @action loadUserDetails = async (navigate: NavigateFunction): Promise<IUser> => {
        if (this.currentUser) {
            return;
        }

        const accounts = msalInstance.getAllAccounts();

        if (accounts.length === 0) {
            msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' });
            return;
        }

        const signedInUser = accounts[0];

        const userDetails = await agent.ApplicationUserDetail.getApplicationUserDetails(signedInUser.localAccountId);

        const isUserAdministrator = userDetails && userDetails.userId === signedInUser.localAccountId;
        if (!isUserAdministrator) {
            navigate('/hibas-bejelentkezes');
            return;
        }

        this.currentUser = new User();
        this.currentUser.email = signedInUser.idTokenClaims.emails[0];
        this.currentUser.name = signedInUser.name;
        this.currentUser.id = userDetails.userId;
        this.currentUser.isBiro = userDetails.isBiro;
        this.currentUser.isDivian = userDetails.isDivian;
        this.currentUser.isMilo = userDetails.isMilo;
        this.currentUser.isMidibiAdmin = userDetails.isMidibiAdmin;
        this.currentUser.role = userDetails.role;

        if (userDetails.companyId) {
            this.currentUser.companyId = userDetails.companyId;
            this.currentUser.companyName = userDetails.companyName;
            this.currentUser.companyShortenedName = userDetails.companyShortenedName;
        } else {
            this.currentUser.storeId = userDetails.storeId;
            this.currentUser.storeName = userDetails.storeName;
        }

        return this.currentUser;
    };

    @action logout = (): void => {
        this.currentUser = null;
        msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' });
    };
}
