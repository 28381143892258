import { computed, observable, action, runInAction, makeObservable } from 'mobx';
import agent from 'api/agent';
import { IRegion } from 'models/store';
import { RootStore } from 'mobx/rootStore';

export default class RegionStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable regions: Map<string, IRegion> = new Map();

    @computed get getRegions(): IRegion[] {
        return Array.from(this.regions.values());
    }

    @action loadRegions = async (): Promise<void> => {
        const regions = await agent.Region.getRegions();
        runInAction(() => {
            this.regions.clear();
            regions.forEach((region: IRegion) => {
                this.regions.set(region.id, region);
            });
        });
    };
}
