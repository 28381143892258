/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IRegion, IShop, IStore, RegionDeliveryPrice } from 'models/store';
import {
    IFeatureOptions,
    IFurniture,
    IFurnitureFilter,
    IFurnitureHeader,
    IFurnitureForStoreAdmin,
    IFurniturePriceLog,
} from 'models/furniture';
import { IPaginatedDto } from 'models/common';
import { IAnalytics } from 'models/analytics';
import { IAdminFrontEndData, ISynchronizationData } from 'models/frontEnd';
import { IOption } from 'models/option';
import { IFilteringColor } from 'models/filteringColor';
import { IImageUploadResult, ImageType } from 'models/image';
import { showErrorToast } from 'utils/customToaster';
import { useNavigate } from 'react-router-dom';
import { IFeature } from 'models/feature';
import { IOrder, IOrderFilter, IOrderHeader } from 'models/order';
import { OrderStatus } from 'models/enums/orderStatus';
import { DivianSyncMode } from 'models/enums/divianSyncMode';
import { IApplicationUserDetails } from 'models/user';
import { acquireAccessToken } from 'utils/tokenService';
import { IFurnitureEditor, IMiloColorEditor } from 'models/furnitureEditor';
import { OrderItemStatus } from 'models/enums/orderItemStatus';
import {
    IMarginCompany,
    IMarginFurnitureType,
    IMinimumMargin,
    IUpsertMinimumMarginDto,
    UpsertMarginCompanyDto,
    UpsertMarginFurnitureTypeDto,
} from 'models/margin';
import { ICompanyWithMargins } from 'models/company';
import { ProcessType } from 'models/enums/processType';
import { OnDeliveryPaymentType } from 'models/enums/onDeliveryPaymentType';
import { FurnitureType } from 'models/enums/furnitureType';
import { ISyncLog } from 'models/syncLog';
import { CreateDiscountDto, IDiscountDto, IFeatureOptionsForDiscount } from 'models/discount';
import { ICategoryDto } from 'models/category';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        if (typeof config !== 'undefined') {
            const accessToken = await acquireAccessToken();

            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            }

            return config;
        }
    },
    (error) => {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(undefined, (error) => {
    if (!error || !error.response) {
        return;
    }

    if (error.message === 'Network Error' && !error.response) {
        showErrorToast('Nem érhető el a szerver!');
    }
    const { status, data, config, headers } = error.response;
    if (status === 400) {
        showErrorToast(data.message);
    } else if (status === 404) {
        showErrorToast('Kiválasztott/keresett adat nem található!');
    } else if (status === 406) {
        showErrorToast(data.message);
    } else if (status === 401) {
        showErrorToast('Nincs jogod a művelethez!');
    }
    // if (status === 400 && config.method === 'get' && data.errors.hasOwnProperty('id')) {
    //     history.push('/notfound');
    // }
    else if (status === 500) {
        showErrorToast('Hiba történt a művelet során! Hiba elküldve a fejlesztői csapatnak!');
    } else if (
        status === 401 &&
        headers['www-authenticate'] === 'Bearer error="invalid_token", error_description="The token is expired"'
    ) {
        window.localStorage.removeItem('jwt');
        const navigate = useNavigate();
        navigate('/');
        showErrorToast('Lejárt a megengedett időkorlát, lépj be újra!');
    }

    throw error.response;
});

const responseBody = (response: AxiosResponse) => response?.data;

const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body?: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    del: (url: string, config?: {}) => axios.delete(url, config).then(responseBody),
    patch: (url: string, body?: {}) => axios.patch(url, body).then(responseBody),
    postFile: (url: string, file: Blob) => {
        const formData = new FormData();
        formData.append('file', file);
        return axios
            .post(url, formData, {
                headers: { 'Content-type': 'multipart/form-data' },
            })
            .then(responseBody);
    },
    postFiles: (url: string, files: Blob[]) => {
        const formData = new FormData();
        files.forEach((file) => formData.append('files', file));
        return axios
            .post(url, formData, {
                headers: { 'Content-type': 'multipart/form-data' },
            })
            .then(responseBody);
    },
};

const Image = {
    uploadImages: (images: Blob[], imageType: ImageType): Promise<IImageUploadResult[]> =>
        requests.postFiles(`/image/addimages/${imageType}`, images),
    uploadImage: (image: Blob, imageType: ImageType): Promise<IImageUploadResult> =>
        requests.postFile(`/image/addimage/${imageType}`, image),
    deleteImage: (imageId: string, imageUrl: string, thumbnailUrl: string): Promise<any> =>
        requests.post(`/image/deleteimage`, { imageId: imageId, imageUrl: imageUrl, thumbnailUrl: thumbnailUrl }),
};

const ApplicationUserDetail = {
    getApplicationUserDetails: (userId: string): Promise<IApplicationUserDetails> =>
        requests.get(`/applicationuserdetail/getapplicationuserdetails/${userId}`),
};

const Store = {
    getFilteredStorePage: (
        pageNumber: number,
        pageSize: number,
        storeName: string,
        orderByType: string,
    ): Promise<IPaginatedDto<IStore>> =>
        requests.post(`/store/getfilteredstorepagequery`, { pageNumber, pageSize, storeName, orderByType }),
    getStoreById: (storeId: string): Promise<IStore> => requests.get(`/store/getstorebyid/${storeId}`),
    getAllByCompanyId: (companyId: string): Promise<IStore[]> => requests.get(`/store/getallbycompanyid/${companyId}`),
    companySpecifiedSearchStore: (companyId: string, fragment: string): Promise<IStore[]> =>
        requests.get(`/store/companyspecifiedsearchstore/${companyId},${fragment}`),
    createStore: (store: IStore): Promise<any> => requests.post('/store/createstore', store),
    updateStore: (store: IStore): Promise<any> => requests.put('/store/updatestore', store),
    upsertbanktransferdetails: (storeId: string, beneficiaryName: string, accountNumber: string): Promise<any> =>
        requests.put('/store/upsertbanktransferdetails', { storeId, beneficiaryName, accountNumber }),
    deleteStore: (storeId: string): Promise<any> => requests.del(`/store/deletestorebyid/${storeId}`),
    setStoreActivation: (storeId: string, isActive: boolean): Promise<string> =>
        requests.post('/store/setstoreactivation', { storeId, isActive }),
    assignUnAssignCompanyToStore: (storeId: string, companyId: string): Promise<string> =>
        requests.post('/store/assignunassigncompanytostore', { storeId, companyId }),
    setDivianStoreActivation: (storeId: string, isEnabled: boolean): Promise<string> =>
        requests.post('/store/setdivianstoreactivation', { storeId, isEnabled }),
    upsertMarginCompany: (marginCompany: UpsertMarginCompanyDto): Promise<any> =>
        requests.post('/store/upsertmargincompany', marginCompany),
    deleteMarginCompany: (id: string, companyId: string, storeId: string): Promise<any> =>
        requests.del(`/store/deletemargincompany`, { data: { id, companyId, storeId } }),
    getAllMarginCompanyByStoreId: (storeId: string): Promise<IMarginCompany[]> =>
        requests.get(`/store/getallmargincompanybystoreid/${storeId}`),
    upsertMarginFurnitureType: (marginFurnitureType: UpsertMarginFurnitureTypeDto): Promise<any> =>
        requests.post('/store/upsertmarginfurnituretype', marginFurnitureType),
    getAllMarginFurnitureTypeByStoreId: (storeId: string): Promise<IMarginFurnitureType[]> =>
        requests.get(`/store/getallmarginfurnituretypebystoreid/${storeId}`),
    deleteMarginFurnitureType: (id: string, furnitureType: FurnitureType, storeId: string): Promise<any> =>
        requests.del(`/store/deletemarginfurnituretype`, { data: { id, furnitureType, storeId } }),
    updateDeliveryPrices: (storeId: string, regionDeliveryPrices: RegionDeliveryPrice[]) =>
        requests.put('/store/updateDeliveryPrices', { storeId: storeId, regionDeliveryPrices: regionDeliveryPrices }),
};

const Shop = {
    getAllShopByStoreId: (): Promise<IShop[]> => requests.get(`/shop/getAllShopByStoreId`),
    updateShopByStoreAdmin: (shop: IShop): Promise<any> =>
        requests.put(`/shop/UpdateShopByStoreAdmin`, {
            shopId: shop.id,
            isShowableOnMap: shop.isShowableOnMap,
            isOfferable: shop.isOfferable,
            businessHours: shop.businessHours,
        }),
    updateShopByMidibiAdmin: (shop: IShop): Promise<any> =>
        requests.put(`/shop/updateShopByMidibiAdmin`, {
            shopId: shop.id,
            isShowableOnMap: shop.isShowableOnMap,
            businessHours: shop.businessHours,
        }),
};

const Company = {
    upsertMinimumMargin: (minimumMargin: IUpsertMinimumMarginDto): Promise<any> =>
        requests.post('/company/upsertminimummargin', minimumMargin),
    getAllMinimumMarginByCompanyId: (companyId: string): Promise<IMinimumMargin[]> =>
        requests.get(`/company/getallminimummarginbycompanyid/${companyId}`),
    getAllCompanyWithMinimumMargins: (): Promise<ICompanyWithMargins[]> =>
        requests.get(`/company/getallcompanywithminimummargins`),
};

const Furniture = {
    getFilteredFurniturePage: (furnitureFilter: IFurnitureFilter): Promise<IPaginatedDto<IFurnitureHeader>> =>
        requests.post(`/furniture/getfilteredfurniturepage`, furnitureFilter),
    getFilteredActiveFurniturePage: (
        furnitureFilter: IFurnitureFilter,
    ): Promise<IPaginatedDto<IFurnitureForStoreAdmin>> =>
        requests.post(`/furniture/getfilteredactivefurniturepage`, furnitureFilter),
    getFurnitureForFurnitureConfiguratorById: (furnitureId: string): Promise<IFurniture> =>
        requests.get(`/furniture/getfurnitureforfurnitureconfiguratorbyid/${furnitureId}`),
    createFurniture: (furniture: IFurniture): Promise<any> => requests.post('/furniture/createfurniture', furniture),
    updateFurniture: (furniture: IFurniture): Promise<any> => requests.put('/furniture/updatefurniture', furniture),
    deleteFurniture: (furnitureId: string): Promise<any> =>
        requests.del(`/furniture/deletefurniturebyid/${furnitureId}`),
    setFurnitureActivation: (furnitureId: string, isActive: boolean): Promise<string> =>
        requests.post('/furniture/setfurnitureactivation', { furnitureId, isActive }),
    setDivianFurnitureActivation: (furnitureId: string, isEnabled: boolean): Promise<string> =>
        requests.post('/furniture/setdivianfurnitureactivation', { furnitureId, isEnabled }),
    getFurnitureListFile: (): Promise<Blob> =>
        axios
            .get('/furniture/getfurniturelistfile', {
                responseType: 'blob',
            })
            .then((response) => new Blob([response.data])),
    getFurniturePricesListFile: (withDiscounts: boolean): Promise<Blob> =>
        axios
            .get(`/furniture/getfurniturepriceslistfile/${withDiscounts}`, {
                responseType: 'blob',
            })
            .then((response) => new Blob([response.data])),
    // setCustomPrice: (customPrices: { furnitureId: string; price: number }[]): Promise<string> =>
    //     requests.post('/furniture/setcustomprice', { customPrices }),
    // setMultipleCustomPrice: (percentage: number, companyId?: string, furnitureType?: FurnitureType): Promise<any> =>
    //     requests.post('/furniture/setmultiplecustomprice', { percentage, companyId, furnitureType }),
    // rollbackCustomPrices: (companyId?: string, furnitureType?: FurnitureType): Promise<any> =>
    //     requests.post('/furniture/rollbackcustomprices', { companyId, furnitureType }),
    getFurnituresByCatalogCode: (catalogCode: string): Promise<IFurniture[]> =>
        requests.get(`/furniture/getfurnituresbycatalogcode/${catalogCode}`),
    catalogSpecifiedSearchFurniture: (catalogCode: string, fragment: string): Promise<IFurniture[]> =>
        requests.get(`/furniture/catalogspecifiedsearchfurniture/${catalogCode},${fragment}`),
    updateFurniturePrices: (file: Blob): Promise<any> => requests.postFile(`/furniture/updatefurnitureprices`, file),
    getFilteredFurniturePriceLogPage: (
        pageNumber: number,
        pageSize: number,
        productCode: string,
    ): Promise<IPaginatedDto<IFurniturePriceLog>> =>
        requests.post(`/furniture/getFilteredFurniturePriceLogPage`, { pageNumber, pageSize, productCode }),
    migrateFurniture: (furnitureId: string): Promise<any> =>
        requests.post(`/furniture/migratefurniture`, { furnitureId: furnitureId }),
    getFurnitureNameById: (id: string): Promise<string> => requests.get(`/furniture/getfurniturenamebyid/${id}`),
};

const FurnitureEditor = {
    upsertMiloColorEditor: (furnitureId: string, miloColorEditor: IMiloColorEditor): Promise<any> =>
        requests.post('/furnitureeditor/upsertmilocoloreditor', { furnitureId, ...miloColorEditor }),
    getFurnitureWithMiloColorEditorById: (furnitureId: string): Promise<IFurniture> =>
        requests.get(`/furnitureeditor/getfurniturewithmilocoloreditorbyid/${furnitureId}`),
    upsertBiroColorEditor: (furnitureBiro: IFurniture): Promise<any> =>
        requests.post('/furnitureeditor/upsertbirocoloreditor', { ...furnitureBiro }),
    getFurnitureWithBiroColorEditorById: (furnitureId: string): Promise<IFurniture> =>
        requests.get(`/furnitureeditor/getfurniturewithbirocoloreditorbyid/${furnitureId}`),
    upsertDivianEditors: (
        furnitureId: string,
        furnitureEditors: IFurnitureEditor[],
        furnitureFeatureOptions: IFeatureOptions[],
    ): Promise<any> =>
        requests.post('/furnitureeditor/upsertdivianeditors', {
            furnitureId: furnitureId,
            furnitureEditors: furnitureEditors,
            furnitureFeatureOptions: furnitureFeatureOptions,
        }),
    getFurnitureWithDivianFurnitureEditorsById: (furnitureId: string): Promise<IFurniture> =>
        requests.get(`/furnitureeditor/getfurniturewithdivianfurnitureeditorsbyid/${furnitureId}`),
};

const Analytics = {
    getAnalytics: (): Promise<IAnalytics> => requests.get('/analytics/getanalytics'),
};

const AdminFrontEnd = {
    getSyncLogs: (): Promise<ISyncLog[]> => requests.get('/adminfrontend/getSyncLogs'),
    getAdminFrontEndData: (): Promise<IAdminFrontEndData> => requests.get('/adminfrontend/getAdminFrontEndData'),
    gcetSynchronizationData: (): Promise<ISynchronizationData> => requests.get(`/adminfrontend/GetSynchronizationData`),
    triggerSync: (divianSyncModes: DivianSyncMode[]): Promise<ISynchronizationData> =>
        requests.post(`/adminfrontend/triggersync`, { divianSyncModes }),
    triggerPurchasePriceCalculation: () => requests.post(`/adminfrontend/triggerpurchasepricecalculation`, {}),
    triggerMinimumPriceCalculation: (companyId: string) =>
        requests.post(`/adminfrontend/triggerminimumpricecalculation`, { companyId: companyId }),
    getIsBackgroundProcessRunning: (processType: ProcessType): Promise<boolean> =>
        requests.post(`/adminfrontend/getisbackgroundprocessrunning`, { processType: processType }),
    toggleStoreFinder: (isStoreFinderEnabled: boolean): Promise<boolean> =>
        requests.put(`/adminfrontend/togglestorefinder`, { isStoreFinderEnabled: isStoreFinderEnabled }),
};

const Region = {
    getRegions: (): Promise<IRegion[]> => requests.get('/region/getregions'),
};

const Option = {
    getAllOptionByCompanyId: (companyId: string): Promise<IOption[]> =>
        requests.get(`/option/getalloptionbycompanyid/${companyId}`),
    getOptionById: (optionId: string): Promise<IOption> => requests.get(`/option/getoptionbyid/${optionId}`),
    searchOption: (fragment: string): Promise<IOption[]> => requests.post('/option/searchoption', { fragment }),
    createOption: (option: IOption): Promise<string> => requests.post('/option/createoption', option),
    updateOption: (option: IOption): Promise<string> => requests.put('/option/updateoption', option),
    deleteOption: (optionId: string): Promise<any> => requests.del(`/option/deleteoptionbyid/${optionId}`),
};

const Category = {
    getAllCategories: (): Promise<ICategoryDto[]> => requests.get('/categories/getallcategory'),
    getFilteredCategoryPage: (
        pageNumber: number,
        pageSize: number,
        nameFragment: string,
        orderByType: string,
    ): Promise<IPaginatedDto<ICategoryDto>> =>
        requests.post(`/categories/getfilteredcategorypage`, { pageNumber, pageSize, nameFragment, orderByType }),
    getCategoryById: (categoryId: string): Promise<ICategoryDto> =>
        requests.get(`/categories/getcategorybyid/${categoryId}`),
    saveCategory: (category: ICategoryDto): Promise<string> => requests.post('/categories/savecategory', category),
    deleteCategory: (categoryId: string): Promise<any> => requests.del(`/categories/deletecategory/${categoryId}`),
};

const Feature = {
    getAllFeatureByCompanyId: (companyId: string): Promise<IFeature[]> =>
        requests.get(`/feature/getallfeaturebycompanyid/${companyId}`),
    getFeatureById: (featureId: string): Promise<IFeature> => requests.get(`/feature/getfeaturebyid/${featureId}`),
    searchFeature: (fragment: string): Promise<IFeature[]> => requests.post('/feature/searchfeature', { fragment }),
    createFeature: (feature: IFeature): Promise<string> => requests.post('/feature/createfeature', feature),
    updateFeature: (feature: IFeature): Promise<string> => requests.put('/feature/updatefeature', feature),
    deleteFeature: (featureId: string): Promise<any> => requests.del(`/feature/deletefeaturebyid/${featureId}`),
    getFeaturesWithDisplayNameByFurnitureId: (furnitureId: string): Promise<IFeature[]> =>
        requests.get(`/feature/getfeatureswithdisplaynamebyfurnitureid/${furnitureId}`),
};

const FilteringColor = {
    getAllFilteringColor: (): Promise<IFilteringColor[]> => requests.get('/filteringcolor/getfilteringcolors'),
    getFilteringColorById: (filteringColorId: string): Promise<IFilteringColor> =>
        requests.get(`/filteringcolor/getfilteringcolorbyid/${filteringColorId}`),
    searchFilteringColor: (fragment: string): Promise<IFilteringColor[]> =>
        requests.post('/filteringcolor/searchfilteringcolor', { fragment }),
    createFilteringColor: (fileringColor: IFilteringColor): Promise<string> =>
        requests.post('/filteringcolor/createfilteringcolor', fileringColor),
    updateFilteringColor: (filteringColor: IFilteringColor): Promise<string> =>
        requests.put('/filteringcolor/updatefilteringcolor', filteringColor),
    deleteFilteringColor: (filteringColorId: string): Promise<any> =>
        requests.del(`/filteringcolor/deletefilteringcolorbyid/${filteringColorId}`),
};

const Order = {
    getFilteredOrderPage: (orderFilter: IOrderFilter): Promise<IPaginatedDto<IOrderHeader>> =>
        requests.post(`/order/getfilteredorderpage`, orderFilter),
    getHeadersOfOrdersByCompanyId: (companyId: string): Promise<IOrderHeader[]> =>
        requests.get(`/order/getheadersofordersbycompanyId/${companyId}`),
    getHeadersOfOrdersByStoreId: (storeId: string): Promise<IOrderHeader[]> =>
        requests.get(`/order/getheadersofordersbystoreid/${storeId}`),
    getOrderForStoreByIdAndStoreId: (orderId: string, storeId: string): Promise<IOrder> =>
        requests.get(`/order/getorderforstorebyidandstoreId/${orderId}/${storeId}`),
    setOrderStatus: (orderId: string, orderStatus: OrderStatus): Promise<any> =>
        requests.put('/order/setorderstatus', { orderId, orderStatus }),
    setOrderItemStatus: (
        orderItemId: string,
        orderItemStatus: OrderItemStatus,
        shopId: string,
        orderStatusChangeMessage: string,
        onDeliveryPayment?: OnDeliveryPaymentType,
        deliveryTime?: string,
        pickupTime?: string,
    ): Promise<any> =>
        requests.patch('/order/setorderitemstatus', {
            orderItemId: orderItemId,
            orderItemStatus: orderItemStatus,
            orderStatusChangeMessage: orderStatusChangeMessage,
            shopId: shopId,
            onDeliveryPayment: onDeliveryPayment,
            deliveryTime: deliveryTime,
            pickupTime: pickupTime,
        }),
    applyGroupedOperation: (
        orderId: string,
        storeId: string,
        orderItemIds: string[],
        status: OrderItemStatus,
        messageToCustomer: string,
        shopId: string,
        onDeliveryPayment?: OnDeliveryPaymentType,
        deliveryTime?: string,
        pickupTime?: string,
    ): Promise<any> =>
        requests.patch('/order/applygroupedoperation', {
            orderId: orderId,
            storeId: storeId,
            orderItemIds: orderItemIds,
            status: status,
            messageToCustomer: messageToCustomer,
            onDeliveryPayment: onDeliveryPayment,
            deliveryTime: deliveryTime,
            pickupTime: pickupTime,
            shopId: shopId,
        }),
    setNoteByStore: (orderId: string, noteByStore: string): Promise<any> =>
        requests.patch('/order/setnotebystore', { orderId, noteByStore }),
};

const Discount = {
    getDataForDiscountById: (furnitureId: string): Promise<IFeatureOptionsForDiscount[]> =>
        requests.get(`/discount/getdatafordiscountbyid/${furnitureId}`),
    getAllDiscountByFurnitureId: (furnitureId: string): Promise<IDiscountDto[]> =>
        requests.get(`/discount/getalldiscountbyfurnitureid/${furnitureId}`),
    createDiscount: (createDiscountDto: CreateDiscountDto): Promise<any> =>
        requests.post('/discount/creatediscount', createDiscountDto),
    deleteDiscountById: (furnitureId: string, discountId: string): Promise<any> =>
        requests.del(`/discount/deletediscountbyid/${furnitureId}/${discountId}`),
};

export default {
    ApplicationUserDetail,
    Image,
    Analytics,
    Store,
    Shop,
    Company,
    Furniture,
    FurnitureEditor,
    AdminFrontEnd,
    Region,
    Option,
    Category,
    Feature,
    FilteringColor,
    Order,
    Discount,
};
