import { ApplicationRole } from './enums/applicationRole';

export interface IUser {
    id: string;
    email: string;
    phoneNumber: string;
    name: string;
    isMidibiAdmin: boolean;
    isDivian: boolean;
    isMilo: boolean;
    isBiro: boolean;
    role: ApplicationRole;
    companyId?: string;
    companyName?: string;
    companyShortenedName?: string;
    storeId?: string;
    storeName?: string;
}

export class User implements IUser {
    id: string;
    email: string;
    phoneNumber: string;
    name: string;
    isMidibiAdmin: boolean;
    isDivian: boolean;
    isMilo: boolean;
    isBiro: boolean;
    role: ApplicationRole;
    companyId?: string;
    companyName?: string;
    companyShortenedName?: string;
    storeId?: string;
    storeName?: string;
}

export interface IApplicationUserDetails {
    userId: string;
    isMidibiAdmin: boolean;
    isDivian: boolean;
    isMilo: boolean;
    isBiro: boolean;
    role: ApplicationRole;
    companyId?: string;
    companyName?: string;
    companyShortenedName?: string;
    storeId?: string;
    storeName?: string;
}
