import { Box, Card, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IAnalytics } from 'models/analytics';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    label: {
        marginLeft: theme.spacing(1),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        height: 48,
        width: 48,
    },
}));

interface IMostViewedLocationsProps {
    isLoading: boolean;
    analytics: IAnalytics;
}

const MostViewedLocations: React.FC<IMostViewedLocationsProps> = ({ isLoading, analytics }) => {
    const styles = useStyles();

    return (
        <Box mt={3}>
            <Card className={styles.root}>
                <Box flexGrow={1}>
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <div>
                            {analytics && analytics.mostViewedFurniture && analytics.mostViewedFurniture.length && (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Név</TableCell>
                                            <TableCell>Kattintások</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {analytics.mostViewedLocations.map(
                                            (furniture: { name: string; numberOfClicks: number }, index: number) => {
                                                return (
                                                    <TableRow hover key={index}>
                                                        <TableCell>{furniture.name}</TableCell>
                                                        <TableCell>{furniture.numberOfClicks}</TableCell>
                                                    </TableRow>
                                                );
                                            },
                                        )}
                                    </TableBody>
                                </Table>
                            )}
                        </div>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

export default MostViewedLocations;
