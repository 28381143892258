import { IBiroColorEditor, IFurnitureEditor, IMiloColorEditor } from './furnitureEditor';
import { IPaginatedFilter } from './common';
import { EditorType } from './enums/editorType';
import { FurnitureType } from './enums/furnitureType';
import { OrderByClauseType } from './enums/orderByType';
import { IBasicImage, IImage } from './image';
import { PricePercentageType } from './enums/pricePercentageType';
import { IOptionForMiloSizing } from './option';

export interface IFurnitureFilter extends IPaginatedFilter {
    furnitureName: string;
    isNewness?: boolean;
    furnitureType?: FurnitureType;
    companyId?: string;
    orderByType?: OrderByClauseType;
}

export class FurnitureFilter implements IFurnitureFilter {
    furnitureName: string;
    isNewness?: boolean;
    furnitureType?: FurnitureType;
    companyId?: string;
    pageNumber: number;
    pageSize: number;
    orderByType?: OrderByClauseType;

    constructor(
        pageSize: number,
        pageNumber: number,
        furnitureName?: string,
        furnitureType?: FurnitureType,
        companyId?: string,
        isNewness?: boolean,
        orderByType?: OrderByClauseType,
    ) {
        this.pageSize = pageSize;
        this.pageNumber = pageNumber;
        this.furnitureName = furnitureName;
        this.furnitureType = furnitureType;
        this.companyId = companyId;
        this.isNewness = isNewness;
        this.orderByType = orderByType;
    }
}

export interface IFurniture {
    id: string;
    createdAt: Date;
    createdBy: string;
    name: string;
    purchasePrice: number;
    price: number;
    priceGap?: number;
    description: string;
    isNewness: boolean;
    isDiscounted: boolean;
    isActive: boolean;
    isColorEditorAssigned: boolean;
    isFurnitureEditorAssigned: boolean;
    isDropdownExclusionNeeded: boolean;
    isEnabledByDivian: boolean;
    type: FurnitureType;
    productCode: string;
    productroupCode: string;
    simplifiedProductCode: string;
    images: IImage[];
    sizes: ISize[];
    filteringColorIds: string[];
    furnitureEditors?: IFurnitureEditor[];
    furnitureFeatureOptions: IFeatureOptions[];
    defaultFurnitureElementsForDynamicPricing: IDefaultFurnitureElement[];
    defaultFurnitureElementsForPurchasePricing: IDefaultFurnitureElement[];
    miloColorEditor: IMiloColorEditor;
    biroColorEditor: IBiroColorEditor;
    sizesForMilo: IOptionForMiloSizing[];
    featureOptionDisplayNames: IFeatureOptionDisplayName[];
}

export class Furniture implements IFurniture {
    id!: string;
    createdAt!: Date;
    createdBy!: string;
    name = '';
    purchasePrice!: number;
    price!: number;
    priceGap?: number;
    description = '';
    isNewness = false;
    isActive = false;
    isDiscounted = false;
    isColorEditorAssigned = false;
    isFurnitureEditorAssigned = false;
    isDropdownExclusionNeeded = false;
    isEnabledByDivian: boolean;
    type: FurnitureType;
    typeInString = '';
    productCode: string;
    productroupCode: string;
    simplifiedProductCode: string;
    images: IImage[] = [];
    sizes: ISize[] = [];
    filteringColorIds: string[] = [];
    featureIds: string[] = [];
    furnitureEditors?: IFurnitureEditor[] = [];
    furnitureFeatureOptions: IFeatureOptions[] = [];
    defaultFurnitureElementsForDynamicPricing: IDefaultFurnitureElement[];
    defaultFurnitureElementsForPurchasePricing: IDefaultFurnitureElement[];
    miloColorEditor: IMiloColorEditor;
    biroColorEditor: IBiroColorEditor;
    sizesForMilo: IOptionForMiloSizing[] = [];
    featureOptionDisplayNames: IFeatureOptionDisplayName[] = [];
}

export interface ISize {
    id: string;
    name: string | undefined;
    value: string | undefined;
}

export class Size implements ISize {
    id!: string;
    name: string | undefined;
    value: string | undefined;

    constructor(name?: string, value?: string) {
        this.name = name;
        this.value = value;
    }
}

export interface IFeatureOptions {
    featureId: string;
    featureCode: string;
    featureName: string;
    editorType?: EditorType;
    isShowableOnFrontend: boolean;
    options: IOptionImage[];
}

export class FeatureOptions implements IFeatureOptions {
    featureId: string;
    featureCode: string;
    featureName: string;
    editorType?: EditorType;
    isShowableOnFrontend: boolean;
    options: IOptionImage[] = [];

    constructor(featureId: string, editorType?: EditorType) {
        this.featureId = featureId;
        this.editorType = editorType;
    }
}

export interface IOptionImage {
    optionId: string;
    isHidden: boolean;
    basicImage: IBasicImage;
}

export class OptionImage implements IOptionImage {
    optionId: string;
    isHidden: boolean;
    basicImage: IBasicImage;

    constructor(optionId: string) {
        this.optionId = optionId;
    }
}

export interface IDefaultFurnitureElement {
    id: string;
    featureCode: string;
    optionCode: string;
}

export class DefaultFurnitureElement implements IDefaultFurnitureElement {
    id: string;
    featureCode: string;
    optionCode: string;

    constructor(featureCode: string, optionCode: string) {
        this.featureCode = featureCode;
        this.optionCode = optionCode;
    }
}

export interface IFurniturePriceLog {
    sentXml: string;
    receivedXml: string;
    calculatedPrice: number;
    productCode: string;
    createdAt: string;
}

export interface IFurnitureForStoreAdmin {
    furnitureName: string;
    furnitureType: FurnitureType;
    pricePercentageType: PricePercentageType;
    percentage: number;
    purchasePrice: number;
    isNewness: boolean;
}

export interface IFurnitureHeader {
    id: string;
    name: string;
    isActive: boolean;
    type: FurnitureType;
    purchasePrice: number;
    minimumPricePercentage: number;
    isNewness: boolean;
    isColorEditorAssigned: boolean;
    price: number;
}

export interface IFeatureOptionDisplayName {
    code: string;
    featureOptionType: string;
    displayName: string;
}
