import { PublicClientApplication } from '@azure/msal-browser';

export const b2cPolicies = {
    names: {
        signIn: process.env.REACT_APP_MIDIBI_SIGN_IN_FLOW,
    },
    authorities: {
        signIn: {
            authority: process.env.REACT_APP_SIGN_IN_AUTHORITY,
        },
    },
    authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN,
};

export const scopes = [process.env.REACT_APP_ACCESS_SCOPE];

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: process.env.REACT_APP_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: process.env.REACT_APP_CACHE_LOCATION, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);
