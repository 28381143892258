import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStoreContext } from 'mobx/rootStore';
import { Input } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { IconButton, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
    signOutButton: {
        marginLeft: theme.spacing(1),
    },
}));

const SignOut: React.FC = () => {
    const styles = useStyles();
    const navigate = useNavigate();
    const { logout } = useContext(RootStoreContext).userStore;

    return (
        <div>
            <IconButton
                className={styles.signOutButton}
                color="inherit"
                onClick={() => {
                    logout();
                    navigate('/');
                }}
            >
                <Input />
            </IconButton>
        </div>
    );
};

export default SignOut;
