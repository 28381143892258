import React, { useState } from 'react';
import { NavigateNext } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Breadcrumbs, Grid, Typography } from '@mui/material';

const Header: React.FC = () => {
    const [isWarningMessageVisible, setWarningMessageVisible] = useState(true);

    return (
        <Box mt={1}>
            <Grid container spacing={3}>
                <Grid item>
                    <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                        <Typography variant="body1" color="textPrimary">
                            Főoldal
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="h3" color="textPrimary">
                        Üdvözöljük a <a>midibi.hu</a> admin felületén!
                    </Typography>
                    {isWarningMessageVisible && (
                        <Box mt={2}>
                            <Alert severity="warning" onClose={() => setWarningMessageVisible(false)}>
                                <AlertTitle>Figyelem!</AlertTitle>
                                Az oldal megfelelő működéséhez <b>NE</b> használjon <b>hirdetésblokkoló</b>{' '}
                                bővítményeket!
                            </Alert>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Header;
