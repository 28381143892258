export interface IFilteringColor {
    id: string;
    name: string;
    urlName: string;
    imageUrl: string;
    createdAt: string;
    modifiedAt: string;
}

export class FilteringColor implements IFilteringColor {
    id!: string;
    name!: string;
    urlName!: string;
    imageUrl!: string;
    createdAt!: string;
    modifiedAt!: string;
}
