import { computed, observable, action, runInAction, makeObservable } from 'mobx';
import agent from 'api/agent';
import { EditMode } from 'models/enums/editMode';
import { IStore, Store } from 'models/store';
import { RootStore } from 'mobx/rootStore';

export default class StoreStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable selectedStore: IStore | null = null;
    @observable selectedStoreId: string | null = null;
    @observable stores: Map<string, IStore> = new Map();
    @observable editMode: EditMode = EditMode.Create;

    @computed get getStores(): IStore[] {
        return Array.from(this.stores.values());
    }

    @computed get hasStoreAllNecessaryFields(): boolean {
        return !!(this.selectedStore?.accountNumber && this.selectedStore?.beneficiaryName);
    }

    @action setSelectedStoreId = (id: string): void => {
        this.selectedStoreId = id;
    };

    @action setEditMode = (editMode: EditMode): void => {
        this.editMode = editMode;
    };

    @action createNewStore = (): void => {
        this.selectedStore = new Store();
    };

    @action setSelectedStore = (store: IStore): void => {
        this.selectedStore = store;
    };

    @action loadSelectedStore = async (): Promise<IStore> => {
        if (!this.selectedStoreId) {
            throw new Error('selectedStoreId was undefined!');
        }

        const store = await agent.Store.getStoreById(this.selectedStoreId);
        this.selectedStore = store;
        return store;
    };

    @action loadAllStoreByCompanyId = async (companyId: string): Promise<void> => {
        const stores = await agent.Store.getAllByCompanyId(companyId);
        runInAction(() => {
            this.stores.clear();
            stores.forEach((store: IStore) => {
                this.stores.set(store.id, store);
            });
        });
    };

    @action companySpecifiedSearchStore = async (companyId: string, fragment: string): Promise<void> => {
        if (fragment) {
            const result = await agent.Store.companySpecifiedSearchStore(companyId, fragment);
            runInAction(() => {
                this.stores.clear();
                result.forEach((store: IStore) => {
                    this.stores.set(store.id, store);
                });
            });
        }
    };

    deleteStore = async (): Promise<boolean> => {
        if (!this.selectedStoreId) {
            throw new Error('selectedStoreId was undefined!');
        }

        return await agent.Store.deleteStore(this.selectedStoreId);
    };
}
