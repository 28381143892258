import { OptionType } from './enums/optionType';

export interface IOption {
    id: string;
    name: string;
    optionCode: string;
    displayName: string;
    optionTypeCode: string;
    optionType: OptionType;
    createdAt: string;
    createdBy: string;
    lastModifiedAt: string;
    lastModifiedBy: string;
}

export class Option implements IOption {
    id!: string;
    name!: string;
    optionCode!: string;
    displayName!: string;
    optionTypeCode!: string;
    optionType!: OptionType;
    createdAt!: string;
    createdBy!: string;
    lastModifiedAt!: string;
    lastModifiedBy!: string;
}

export interface IOptionWithRotation {
    id: string;
    name: string;
    optionCode: string;
    displayName: string;
    optionTypeCode: string;
    optionType: OptionType;
    createdAt: string;
    createdBy: string;
    lastModifiedAt: string;
    lastModifiedBy: string;
    rotateX: number;
    rotateY: number;
    rotateZ: number;
}

export class OptionWithRotation implements IOptionWithRotation {
    id!: string;
    name!: string;
    optionCode!: string;
    displayName!: string;
    optionTypeCode!: string;
    optionType!: OptionType;
    createdAt!: string;
    createdBy!: string;
    lastModifiedAt!: string;
    lastModifiedBy!: string;
    rotateX!: number;
    rotateY!: number;
    rotateZ!: number;

    constructor(
        id: string,
        displayName: string,
        createdAt: string,
        lastModified: string,
        rotateX: number,
        rotateY: number,
        rotateZ: number,
    ) {
        this.id = id;
        this.displayName = displayName;
        this.createdAt = createdAt;
        this.lastModifiedAt = lastModified;
        this.rotateX = rotateX;
        this.rotateY = rotateY;
        this.rotateZ = rotateZ;
    }
}

export interface IOptionForMiloSizing {
    id: string;
    displayName: string;
    purchasePrice: number;
}

export class OptionForMiloSizing implements IOptionForMiloSizing {
    id: string;
    displayName: string;
    purchasePrice: number;

    constructor(displayName: string, purchasePrice: number) {
        this.displayName = displayName;
        this.purchasePrice = purchasePrice;
    }
}
