import { IColorLayer } from './colorLayer';
import { IDropzone } from './dropzone';
import { BasicImage, IBasicImage } from './image';

export interface IFurnitureEditor {
    name: string;
    colorEditorBaseImgUrl: string;
    furnitureEditorBaseImgUrl: string;
    assignedSizes: string[];
    colorLayers: IColorLayer[];
    dropzones: IDropzone[];
    featureBasicImages: IFeatureBasicImage[];
    baseImgUrlWithMirrors: IBasicImage[];
}

export class FurnitureEditor implements IFurnitureEditor {
    name: string;
    colorEditorBaseImgUrl: string;
    furnitureEditorBaseImgUrl: string;
    assignedSizes: string[];
    colorLayers: IColorLayer[] = [];
    dropzones: IDropzone[] = [];
    featureBasicImages: IFeatureBasicImage[] = [];
    baseImgUrlWithMirrors: IBasicImage[] = [];
}

export interface IFeatureBasicImage {
    featureId: string;
    basicImage: IBasicImage;
}

export class FeatureBasicImage implements IFeatureBasicImage {
    featureId: string;
    basicImage: IBasicImage;

    constructor(featureId: string, basicImage: IBasicImage) {
        this.featureId = featureId;
        this.basicImage = basicImage;
    }
}

export interface IMiloColorEditor {
    colorEditorBaseImgUrl: string;
    baseColorLayers: IColorLayer[];
    miloColorLayers: IMiloColorLayer[];
}

export class MiloColorEditor implements IMiloColorEditor {
    colorEditorBaseImgUrl: string;
    baseColorLayers: IColorLayer[] = [];
    miloColorLayers: IMiloColorLayer[] = [];
}

export interface IMiloColorLayer {
    featureId: string;
    buttonPosX: number;
    buttonPosY: number;
    defaultColorSampleImageUrl: string;
    miloOptionImages: IMiloOptionImage[];
}

export class MiloColorLayer implements IMiloColorLayer {
    featureId: string;
    name: string;
    buttonPosX = 0;
    buttonPosY = 0;
    defaultColorSampleImageUrl: string;
    miloOptionImages: IMiloOptionImage[] = [];

    constructor(name?: string, featureId?: string) {
        if (name) {
            this.name = name;
        }

        if (featureId) {
            this.featureId = featureId;
        }
    }
}

export interface IMiloOptionImage {
    optionId: string;
    featureId: string;
    colorSampleImageUrl: string;
}

export class MiloOptionImage implements IMiloOptionImage {
    optionId: string;
    featureId: string;
    colorSampleImageUrl: string;

    constructor(optionId: string, featureId: string, colorSampleImageUrl: string) {
        this.optionId = optionId;
        this.featureId = featureId;
        this.colorSampleImageUrl = colorSampleImageUrl;
    }
}

export interface IBiroColorEditor {
    colorEditorBaseImgUrl: string;
    isColorCombinationEnabled: boolean;
    colorLayers: IColorLayer[];
    colorCombinations: IColorCombination[];
}

export class BiroColorEditor implements IBiroColorEditor {
    colorEditorBaseImgUrl: string;
    isColorCombinationEnabled: boolean;
    colorLayers: IColorLayer[] = [];
    colorCombinations: IColorCombination[] = [];
}

export interface IColorCombination {
    colorCombinationItems: IColorCombinationItem[];
}

export class ColorCombination implements IColorCombination {
    colorCombinationItems: IColorCombinationItem[] = [];
}

export interface IColorCombinationItem {
    featureId: string;
    optionId: string;
}

export class ColorCombinationItem implements IColorCombinationItem {
    featureId: string;
    optionId: string;

    constructor(featureId: string, optionId: string) {
        this.featureId = featureId;
        this.optionId = optionId;
    }
}
