import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import agent from 'api/agent';
import { Feature, IFeature } from 'models/feature';
import { EditMode } from 'models/enums/editMode';
import { FeatureOptions, Furniture, IFeatureOptions, IFurniture, ISize, OptionImage } from 'models/furniture';
import { IImage } from 'models/image';
import { RootStore } from 'mobx/rootStore';
import { FurnitureType } from 'models/enums/furnitureType';
import { IOption, IOptionForMiloSizing } from 'models/option';
import { DivianShortenedCompanyName } from 'utils/appConstants';

export default class FurnitureStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable selectedFurniture: IFurniture | null = null;
    @observable selectedFurnitureId: string | null = null;
    @observable allFurniture: Map<string, IFurniture> = new Map();
    @observable allNewFurniture: Map<string, IFurniture> = new Map();
    @observable catalog: Map<string, IFurniture> = new Map();
    @observable editMode: EditMode = EditMode.Create;
    @observable selectedFeatures: IFeature[] | null = null;

    @computed get getAllFurniture(): IFurniture[] {
        return Array.from(this.allFurniture.values());
    }

    @computed get getAllNewFurniture(): IFurniture[] {
        return Array.from(this.allNewFurniture.values());
    }

    @computed get getFurnituresFilteringColors(): string[] {
        return this.selectedFurniture?.filteringColorIds || [];
    }

    @computed get getFurnituresFeatureIds(): string[] {
        return this.selectedFurniture?.furnitureFeatureOptions?.map((ffo) => ffo.featureId) || [];
    }

    @computed get getCatalog(): IFurniture[] {
        return Array.from(this.catalog.values());
    }

    // BIGG TODO!! THIS IS BIRO RELATED CODE!! Can be ommited!!! NEEDS FURTHER INVESTIGATION
    @action setIsColorEditorEnabled = (isChecked: boolean): void => {
        this.selectedFurniture.isColorEditorAssigned = isChecked;

        // if (
        //     this.rootStore.userStore.currentUser?.companyShortenedName === BiroShortenedCompanyName &&
        //     this.selectedFurniture.furnitureEditors.length === 0
        // ) {
        //     const furnitureEditor = new FurnitureEditor();
        //     furnitureEditor.name = 'Szinszerkesztő';
        //     furnitureEditor.colorLayers = this.getColorLayersForFurniture();
        //     this.selectedFurniture.furnitureEditors.push(furnitureEditor);
        // }
    };

    @action setIsFurnitureEditorEnabled = (isChecked: boolean): void => {
        if (!this.selectedFurniture.isColorEditorAssigned && isChecked) {
            this.selectedFurniture.isColorEditorAssigned = isChecked;
        }

        this.selectedFurniture.isFurnitureEditorAssigned = isChecked;
    };

    @action setIsDropdownExclusionNeeded = (isChecked: boolean): void => {
        if (this.selectedFurniture.isFurnitureEditorAssigned) {
            return;
        }

        this.selectedFurniture.isDropdownExclusionNeeded = isChecked;
    };

    @action setSelectedFurnitureId = (id: string): void => {
        this.selectedFurnitureId = id;
    };

    @action setEditMode = (editMode: EditMode): void => {
        this.editMode = editMode;
    };

    @action createNewFurniture = (): void => {
        this.clearSelectedColorGroups();
        this.selectedFurniture = new Furniture();
    };

    @action addGeneralInformations = (values: {
        descriptionFromQuill: string;
        sizes: ISize[];
        name: string;
        purchasePrice: number;
        type: FurnitureType;
        isNewness: boolean;
        isDiscounted: boolean;
    }): void => {
        if (!this.selectedFurniture) {
            throw new Error('selectedFurniture was undefined!');
        }

        this.selectedFurniture.name = values.name;
        this.selectedFurniture.purchasePrice = values.purchasePrice;
        this.selectedFurniture.type = values.type;
        this.selectedFurniture.sizes = values.sizes;
        this.selectedFurniture.isNewness = values.isNewness;
        this.selectedFurniture.isDiscounted = values.isDiscounted;
        this.selectedFurniture.description = values.descriptionFromQuill;

        this.selectedFurniture = { ...this.selectedFurniture };
    };

    @action addImages = (images: IImage[]): void => {
        if (!this.selectedFurniture) {
            throw new Error('selectedFurniture was undefined!');
        }

        this.selectedFurniture.images = images;
    };

    @action setFurnituresPriceGap = (priceGap: number | null) => {
        this.selectedFurniture.priceGap = priceGap;
    };

    @action addSize = (size: ISize): ISize[] => {
        if (!this.selectedFurniture) {
            throw new Error('selectedFurniture was undefined!');
        }

        this.selectedFurniture.sizes.push(size);
        return this.selectedFurniture.sizes;
    };

    @action addSizeForMilo = (sizeForMilo: IOptionForMiloSizing): IOptionForMiloSizing[] => {
        if (!this.selectedFurniture) {
            throw new Error('selectedFurniture was undefined!');
        }

        if (!this.selectedFurniture.sizesForMilo) this.selectedFurniture.sizesForMilo = [];

        this.selectedFurniture.sizesForMilo.push(sizeForMilo);
        return this.selectedFurniture.sizesForMilo;
    };

    @action removeSize = (size: ISize): ISize[] => {
        if (!this.selectedFurniture) {
            throw new Error('selectedFurniture was undefined!');
        }

        const sizeToDelete = this.selectedFurniture.sizes.find((s) => s.name === size.name && s.value === size.value);

        if (!size) {
            throw new Error('Searched size was undefined!');
        }

        const idx = this.selectedFurniture.sizes.indexOf(sizeToDelete);
        this.selectedFurniture.sizes.splice(idx, 1);
        return this.selectedFurniture.sizes;
    };

    @action removeSizeForMilo = (sizeForMilo: IOptionForMiloSizing): IOptionForMiloSizing[] => {
        if (!this.selectedFurniture) {
            throw new Error('selectedFurniture was undefined!');
        }

        const sizeForMiloToDelete = this.selectedFurniture.sizesForMilo.find(
            (s) => s.displayName === sizeForMilo.displayName && s.purchasePrice === sizeForMilo.purchasePrice,
        );

        if (!sizeForMilo) {
            throw new Error('Searched size for milo was undefined!');
        }

        const idx = this.selectedFurniture.sizesForMilo.indexOf(sizeForMiloToDelete);
        this.selectedFurniture.sizesForMilo.splice(idx, 1);
        return this.selectedFurniture.sizesForMilo;
    };

    @action updateSize = (toBeUpdatedSize: ISize): ISize[] => {
        const indexOfSize = this.selectedFurniture.sizes.findIndex((size) => size === toBeUpdatedSize);
        this.selectedFurniture.sizes[indexOfSize] = toBeUpdatedSize;
        return this.selectedFurniture.sizes;
    };

    @action updateSizeForMilo = (toBeUpdatedSizeForMilo: IOptionForMiloSizing): IOptionForMiloSizing[] => {
        const idx = this.selectedFurniture.sizesForMilo.findIndex(
            (sizeForMilo) => sizeForMilo === toBeUpdatedSizeForMilo,
        );
        this.selectedFurniture.sizesForMilo[idx] = toBeUpdatedSizeForMilo;
        return this.selectedFurniture.sizesForMilo;
    };

    @action clearSizeForMilo = () => {
        this.selectedFurniture.sizesForMilo = [];
    };

    @action loadSelectedFurniture = async (): Promise<void> => {
        if (!this.selectedFurnitureId) {
            throw new Error('selectedFurnitureId was undefined!');
        }

        this.selectedFurniture = await agent.Furniture.getFurnitureForFurnitureConfiguratorById(
            this.selectedFurnitureId,
        );
    };

    deleteFurniture = async (): Promise<boolean> => {
        if (!this.selectedFurnitureId) {
            throw new Error('selectedFurnitureId was undefined!');
        }

        return await agent.Furniture.deleteFurniture(this.selectedFurnitureId);
    };

    @action addSelectedFilteringColor = (filteringColorId: string): void => {
        if (!this.selectedFurniture) {
            throw new Error('selectedFurniture was undefined!');
        }

        this.selectedFurniture.filteringColorIds.push(filteringColorId);
    };

    @action removeSelectedFilteringColor = (filteringColorId: string): void => {
        if (!this.selectedFurniture) {
            throw new Error('selectedFurniture was undefined!');
        }

        this.selectedFurniture.filteringColorIds = this.selectedFurniture.filteringColorIds.filter(
            (id) => id !== filteringColorId,
        );
    };

    @action clearSelectedColorGroups = (): void => {
        this.selectedFeatures = [];
    };

    @action setSelectedFeaturesByFeatureIds = (features: IFeature[]): void => {
        if (!this.selectedFurniture) {
            throw new Error('selectedFurniture was undefined!');
        }

        if (this.rootStore.userStore.currentUser.companyShortenedName === DivianShortenedCompanyName) {
            this.selectedFeatures = this.selectedFurniture.furnitureFeatureOptions.map((featureOption) => {
                const feature = new Feature();
                feature.id = featureOption.featureId;
                feature.editorType = featureOption.editorType;
                feature.featureCode = featureOption.featureCode;
                feature.displayName = featureOption.featureName;
                feature.name = featureOption.featureName;
                return feature;
            });
        } else {
            this.selectedFeatures = features.filter((feature) =>
                this.selectedFurniture?.furnitureFeatureOptions?.map((ffo) => ffo.featureId).includes(feature.id),
            );
        }
    };

    @action addSelectedFeature = (feature: IFeature): void => {
        if (!this.selectedFurniture || !this.selectedFeatures) {
            throw new Error('selectedFurniture or selectedColorGroups was undefined!');
        }

        this.selectedFeatures.push(feature);
        this.selectedFurniture.furnitureFeatureOptions.push(new FeatureOptions(feature.id));
    };

    @action removeSelectedFeature = (feature: IFeature): void => {
        if (!this.selectedFurniture || !this.selectedFeatures) {
            throw new Error('selectedFurniture or selectedColorGroups was undefined!');
        }

        // Remove Feature from FurnitureFeatureOptions
        this.selectedFeatures = this.selectedFeatures.filter((selectedFeature) => selectedFeature.id !== feature.id);
        this.selectedFurniture.furnitureFeatureOptions = this.selectedFurniture.furnitureFeatureOptions.filter(
            (ffo) => ffo.featureId !== feature.id,
        );

        // Remove Feature from ImageColorData
        this.selectedFurniture.images.forEach((img) => {
            img.imageColorData = img.imageColorData.filter((imgColorData) => imgColorData.featureId !== feature.id);
        });
    };

    @action setFurnitureFeatureOptions = (featureId: string, options: IOption[]): IFeatureOptions[] => {
        const featureOption = this.selectedFurniture?.furnitureFeatureOptions?.find(
            (ffo) => ffo.featureId === featureId,
        );

        featureOption.options = options.map((option) => new OptionImage(option.id));

        return this.selectedFurniture.furnitureFeatureOptions;
    };

    @action loadCatalog = async (catalogCode: string): Promise<void> => {
        const furnitures = await agent.Furniture.getFurnituresByCatalogCode(catalogCode);
        runInAction(() => {
            this.catalog.clear();
            furnitures.forEach((furniture: IFurniture) => {
                this.catalog.set(furniture.id, furniture);
            });
        });
    };

    @action catalogSpecifiedSearchFeature = async (catalogCode: string, fragment: string): Promise<void> => {
        if (fragment) {
            const result = await agent.Furniture.catalogSpecifiedSearchFurniture(catalogCode, fragment);
            runInAction(() => {
                this.catalog.clear();
                result.forEach((feature: IFurniture) => {
                    this.catalog.set(feature.id, feature);
                });
            });
        }
    };

    @action setOptionHide = (featureId: string, optionId: string, newValue: boolean): void => {
        this.selectedFurniture.furnitureFeatureOptions
            .find((ffo) => ffo.featureId == featureId)
            .options.find((o) => o.optionId == optionId).isHidden = newValue;

        this.selectedFurniture = { ...this.selectedFurniture };
    };
}
