import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    Checkbox,
    Chip,
    CircularProgress,
    Container,
    FormControlLabel,
    List,
    ListItem,
    Tab,
    Tabs,
} from '@mui/material';
import {
    Analytics,
    Header,
    MostViewedFurnitureTypes,
    MostViewedLocations,
    MostViewedFurnitures,
    ViewTrends,
    StoreInfoEditor,
    ShopInfoEditor,
} from './components';
import CustomPage from 'components/CustomPage';
import { useRootStore } from 'mobx/rootStore';
import { ApplicationRole } from 'models/enums/applicationRole';
import makeStyles from '@mui/styles/makeStyles';
import TabPanel from 'components/TabPanel';
import { BiroShortenedCompanyName, DivianShortenedCompanyName, MiloShortenedCompanyName } from 'utils/appConstants';
import agent from 'api/agent';
import { AutorenewOutlined, CachedOutlined } from '@mui/icons-material';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';
import { ProcessType } from 'models/enums/processType';
import RegionDeliveryPrices from './components/StoreInfoEditor/components/RegionDeliveryPrices';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

const Home: React.FC = () => {
    const styles = useStyles();
    const [tabValue, setTabValue] = React.useState(0);
    const handleChange = (_, newValue) => {
        setTabValue(newValue);
    };
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { loadAdminFrontEndData } = useRootStore().frontEndStore;
    const { setSelectedStoreId, selectedStoreId } = useRootStore().storeStore;
    const { loadAnalytics, analytics } = useRootStore().analyticsStore;
    const { currentUser } = useRootStore().userStore;
    const [isRunningPurchasePrice, setIsRunningPurchasePrice] = useState<boolean>();
    const [isRunningMinimumPrice, setIsRunningMinimumPrice] = useState<boolean>();
    const [isStoreFinderEnabed, setIsStoreFinderEnabed] = useState<boolean>(false);
    const [storeFinderToggleLoading, setStoreFinderToggleLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        if (currentUser.role === ApplicationRole.StoreAdministrator && currentUser.storeId) {
            setSelectedStoreId(currentUser.storeId);
        }
        if (currentUser.role === ApplicationRole.MidibiAdministrator) {
            loadAnalytics().finally(() => setIsLoading(false));
        } else if (currentUser.role === ApplicationRole.StoreAdministrator) {
            loadAnalytics().finally(() => setIsLoading(false));
        }
    }, [selectedStoreId]);

    useEffect(() => {
        if (currentUser.role === ApplicationRole.MidibiAdministrator) {
            if (currentUser.companyShortenedName === DivianShortenedCompanyName) {
                agent.AdminFrontEnd.getIsBackgroundProcessRunning(ProcessType.PurchasePriceDivian).then((result) =>
                    setIsRunningPurchasePrice(result),
                );
                setStoreFinderToggleLoading(true);
                loadAdminFrontEndData()
                    .then((adminFrontEndData) => setIsStoreFinderEnabed(adminFrontEndData.isStoreFinderEnabled))
                    .finally(() => setStoreFinderToggleLoading(false));
            }
            let processType: ProcessType;
            switch (currentUser.companyShortenedName) {
                case BiroShortenedCompanyName:
                    processType = ProcessType.MinimumPriceBiro;
                    break;
                case DivianShortenedCompanyName:
                    processType = ProcessType.MinimumPriceDivian;
                    break;
                case MiloShortenedCompanyName:
                    processType = ProcessType.MinimumPriceMilo;
                    break;
            }
            agent.AdminFrontEnd.getIsBackgroundProcessRunning(processType).then((result) =>
                setIsRunningMinimumPrice(result),
            );
        }
    }, []);

    const triggerPurchasePriceCalculationHandler = () => {
        setIsRunningPurchasePrice(true);
        agent.AdminFrontEnd.triggerPurchasePriceCalculation()
            .then(() => {
                showSuccessToast('Sikeres beszerzési árazás futtatás!');
                setIsRunningPurchasePrice(true);
            })
            .catch(() => showErrorToast('Sikertelen beszerzési árazás futtatás!'));
    };

    const triggerMinimumPriceCalculationHandler = () => {
        agent.AdminFrontEnd.triggerMinimumPriceCalculation(currentUser.companyId)
            .then(() => {
                showSuccessToast('Sikeres minimum árazás futtatás!');
                setIsRunningMinimumPrice(true);
            })
            .catch(() => showErrorToast('Sikertelen minimum árazás futtatás!'));
    };

    const toggleStoreFinderHandler = (_, checked: boolean) => {
        setStoreFinderToggleLoading(true);
        agent.AdminFrontEnd.toggleStoreFinder(checked)
            .then(() => {
                loadAdminFrontEndData()
                    .then((adminFrontEndData) => {
                        setIsStoreFinderEnabed(adminFrontEndData.isStoreFinderEnabled);
                        showSuccessToast(`Üzlet kereső ${checked ? 'bekapcsolva' : 'kikapcsolva'}!`);
                    })
                    .finally(() => setStoreFinderToggleLoading(false));
            })
            .catch(() => showErrorToast(`Üzlet kereső ${checked ? 'bekapcsolása' : 'kikapcsolása'} sikertelen!`));
    };

    return (
        <CustomPage title="Főoldal | MIDIBI - Admin">
            <Container maxWidth={false}>
                <Header />
                {currentUser.isMidibiAdmin && (
                    <Box mt={2}>
                        <List>
                            {currentUser.companyShortenedName === DivianShortenedCompanyName && (
                                <ListItem>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<CachedOutlined />}
                                        disabled={isRunningPurchasePrice}
                                        onClick={triggerPurchasePriceCalculationHandler}
                                    >
                                        Beszerzési árazás futtatása
                                    </Button>
                                    <Box ml={1}>
                                        <Chip label={`Státusz: ${isRunningPurchasePrice ? 'Fut' : 'Nem fut'}`} />
                                    </Box>
                                </ListItem>
                            )}
                            <ListItem>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AutorenewOutlined />}
                                    disabled={isRunningMinimumPrice}
                                    onClick={triggerMinimumPriceCalculationHandler}
                                >
                                    Minimum árazás futtatása
                                </Button>
                                <Box ml={1}>
                                    <Chip label={`Státusz: ${isRunningMinimumPrice ? 'Fut' : 'Nem fut'}`} />
                                </Box>
                            </ListItem>
                            {currentUser.companyShortenedName === DivianShortenedCompanyName && (
                                <>
                                    {storeFinderToggleLoading ? (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ height: '100%' }}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <ListItem>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isStoreFinderEnabed}
                                                        name="isActive"
                                                        onChange={toggleStoreFinderHandler}
                                                        color="primary"
                                                    />
                                                }
                                                label="Üzlet kereső a bútor oldalon bekapcsolva"
                                            />
                                        </ListItem>
                                    )}
                                </>
                            )}
                        </List>
                    </Box>
                )}
                <Box mt={2}>
                    <Analytics isLoading={isLoading} analytics={analytics} />
                </Box>
                {currentUser.role === ApplicationRole.StoreAdministrator &&
                    selectedStoreId &&
                    selectedStoreId === currentUser.storeId && (
                        <>
                            <StoreInfoEditor />
                            <RegionDeliveryPrices />
                            <ShopInfoEditor />
                        </>
                    )}
                {currentUser.role === ApplicationRole.MidibiAdministrator && (
                    <>
                        <ViewTrends isLoading={isLoading} analytics={analytics} />
                        <MostViewedFurnitureTypes isLoading={isLoading} analytics={analytics} />
                        <Box mt={2}>
                            <Card>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={styles.root}
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                >
                                    <Tab label="Legtöbbet keresett bútorok" />
                                    <Tab label="Legtöbbet keresett városok" />
                                </Tabs>
                                <TabPanel value={tabValue} index={0}>
                                    <MostViewedFurnitures isLoading={isLoading} analytics={analytics} />
                                </TabPanel>
                                <TabPanel value={tabValue} index={1}>
                                    <MostViewedLocations isLoading={isLoading} analytics={analytics} />
                                </TabPanel>
                            </Card>
                        </Box>
                    </>
                )}
            </Container>
        </CustomPage>
    );
};

export default Home;
