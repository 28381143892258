import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import agent from 'api/agent';
import { FilteringColor, IFilteringColor } from 'models/filteringColor';
import { EditMode } from 'models/enums/editMode';
import { IBasicImage } from 'models/image';
import { RootStore } from 'mobx/rootStore';

export default class FilteringColorStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable allFilteringColor: Map<string, IFilteringColor> = new Map();
    @observable selectedFilteringColor: IFilteringColor | null = null;
    @observable selectedFilteringColorId: string | null = null;
    @observable editMode: EditMode = EditMode.Create;

    @computed get getAllFilteringColor(): IFilteringColor[] {
        return Array.from(this.allFilteringColor.values());
    }

    @action createNewFilteringColor = (): void => {
        this.selectedFilteringColor = new FilteringColor();
    };

    @action addImage = (imageUrl: string): void => {
        if (!this.selectedFilteringColor) {
            throw new Error('selectedFilteringColor was undefined!');
        }

        this.selectedFilteringColor.imageUrl = imageUrl;
    };

    @action addFilteringColorName = (name: string): void => {
        if (!this.selectedFilteringColor) {
            throw new Error('selectedFilteringColor was undefined!');
        }

        this.selectedFilteringColor.name = name;
    };

    @action setEditMode = (editMode: EditMode): void => {
        this.editMode = editMode;
    };

    @action setSelectedFilteringColorId = (id: string): void => {
        this.selectedFilteringColorId = id;
    };

    @action loadAllFilteringColor = async (): Promise<void> => {
        const filteringColors = await agent.FilteringColor.getAllFilteringColor();
        runInAction(() => {
            this.allFilteringColor.clear();
            filteringColors.forEach((filteringColor: IFilteringColor) => {
                this.allFilteringColor.set(filteringColor.id, filteringColor);
            });
        });
    };

    @action loadSelectedFilteringColor = async (): Promise<void> => {
        if (!this.selectedFilteringColorId) {
            throw new Error('selectedFilteringColor was undefined!');
        }

        const filteringColor = await agent.FilteringColor.getFilteringColorById(this.selectedFilteringColorId);
        runInAction(() => {
            this.selectedFilteringColor = filteringColor;
        });
    };

    @action searchFilteringColor = async (fragment: string): Promise<void> => {
        const allFilteringColor = await agent.FilteringColor.searchFilteringColor(fragment);
        runInAction(() => {
            this.allFilteringColor.clear();
            allFilteringColor.forEach((filteringColor: IFilteringColor) => {
                this.allFilteringColor.set(filteringColor.id, filteringColor);
            });
        });
    };

    deleteFilteringColor = async (): Promise<boolean> => {
        if (!this.selectedFilteringColorId) {
            throw new Error('selectedFilteringColor was undefined!');
        }

        return await agent.FilteringColor.deleteFilteringColor(this.selectedFilteringColorId);
    };
}
