import { MirrorType } from './enums/mirrorType';
import { IOption } from './option';

export interface IImage {
    id: string;
    isShowable: boolean;
    isInDefaultGallery: boolean;
    isColorsNeeded: boolean;
    link: string;
    thumbnailLink: string;
    alt: string;
    imageColorData: ImageColorData[];
}

export interface IBasicImage {
    id: string;
    link: string;
    mirrorType?: MirrorType;
}

export interface IImageColorData {
    featureId: string;
    optionId: string;
    optionName: string;
}

export interface IImageUploadResult {
    imageUrl: string;
    thumbnailUrl: string;
}

export class Image implements IImage {
    id!: string;
    isShowable!: boolean;
    isInDefaultGallery!: boolean;
    isColorsNeeded!: boolean;
    link!: string;
    thumbnailLink!: string;
    alt!: string;
    color!: IOption;
    imageColorData: ImageColorData[] = [];
}

export class BasicImage implements IBasicImage {
    id!: string;
    link!: string;
    mirrorType?: MirrorType;

    constructor(link: string, mirrorType?: MirrorType) {
        this.link = link;
        if (typeof mirrorType !== 'undefined') {
            this.mirrorType = mirrorType;
        }
    }
}

export class ImageColorData implements IImageColorData {
    featureId!: string;
    optionId!: string;
    optionName!: string;
}

export enum ImageType {
    FurnitureImage,
    FilteringColorImage,
    ColorSampleImage,
    ColorEditorBaseImage,
    FurnitureEditorBaseImage,
    UniqueEditorImage,
    FurnitureElementImage,
    DefaultColorSampleImage,
}
