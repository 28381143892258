import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from 'mobx/rootStore';
import { Formik } from 'formik';
import AlertDialog from 'components/AlertDialog';
import { IRegion, IStore } from 'models/store';
import * as Yup from 'yup';
import agent from 'api/agent';
import { showSuccessToast } from 'utils/customToaster';
import { makeStyles } from '@mui/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import {
    AccountBalanceOutlined,
    ContactPageOutlined,
    EmailOutlined,
    ExpandMore,
    Explore,
    Home,
    LocationCity,
    PersonOutline,
    Public,
} from '@mui/icons-material';

const useStyles = makeStyles(() => ({
    icon: {
        marginTop: 15,
        fontSize: 30,
        marginRight: 10,
    },
    formControl: {
        minWidth: 120,
    },
}));

const StoreInfoEditor: React.FC = () => {
    const styles = useStyles();
    const { selectedStoreId, loadSelectedStore, selectedStore } = useContext(RootStoreContext).storeStore;
    const { currentUser } = useContext(RootStoreContext).userStore;
    const [isLoading, setLoading] = useState(false);
    const [isAlertDialogOpen, setAlertDialogOpen] = useState(false);
    const { loadRegions, getRegions } = useContext(RootStoreContext).regionStore;
    const [beneficiaryName, setBeneficiaryName] = useState<string>();
    const [accountNumber, setAccountNumber] = useState<string>();

    useEffect(() => {
        setLoading(true);
        if (selectedStoreId) {
            Promise.all([loadSelectedStore(), loadRegions()]).finally(() => {
                setLoading(false);
            });
        }
    }, [selectedStoreId]);

    const handleSubmit = (store: IStore, setSubmitting: (isSubmitting: boolean) => void) => {
        store.region = getRegions.find((region) => region.name == store.region.name);
        setBeneficiaryName(store.beneficiaryName);
        setAccountNumber(store.accountNumber);
        setAlertDialogOpen(true);
        setSubmitting(false);
    };

    const saveBankTransferDetails = () => {
        setLoading(true);

        agent.Store.upsertbanktransferdetails(currentUser.storeId, beneficiaryName, accountNumber)
            .then(async () => {
                showSuccessToast('Cég adatok mentése sikeres!');
                await loadSelectedStore();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Container maxWidth={false} style={{ width: '100%', padding: '0', paddingTop: '24px' }}>
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    <Accordion sx={{ width: '100%', marginTop: 1 }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Cégem adatai</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Formik
                                initialValues={{
                                    ...selectedStore,
                                    regionName: selectedStore?.region?.name,
                                }}
                                validationSchema={Yup.object().shape({
                                    accountNumber: Yup.string()
                                        .required('Töltsd ki ezt a mezőt!')
                                        .typeError('Töltsd ki ezt a mezőt!')
                                        .matches(
                                            /^[0-9]{8}-[0-9]{8}-[0-9]{8}$/,
                                            'A xxxxxxxx-xxxxxxxx-xxxxxxxx forma szerint add meg a számlaszámot!',
                                        ),
                                    beneficiaryName: Yup.string()
                                        .required('Töltsd ki ezt a mezőt!')
                                        .typeError('Töltsd ki ezt a mezőt!'),
                                })}
                                enableReinitialize
                                onSubmit={(values, { setSubmitting }) => {
                                    handleSubmit(values, setSubmitting);
                                }}
                            >
                                {({ errors, handleBlur, handleChange, handleSubmit, touched, values, isValid }) => (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <Box display="flex" m={1}>
                                            <PersonOutline
                                                className={styles.icon}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block',
                                                        md: 'block',
                                                        lg: 'block',
                                                        xl: 'block',
                                                    },
                                                }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                name="name"
                                                value={values?.name || ''}
                                                disabled
                                            />
                                        </Box>
                                        <Box display="flex" m={1}>
                                            <Public
                                                className={styles.icon}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block',
                                                        md: 'block',
                                                        lg: 'block',
                                                        xl: 'block',
                                                    },
                                                }}
                                            />
                                            <FormControl
                                                variant="outlined"
                                                className={styles.formControl}
                                                fullWidth
                                                disabled
                                            >
                                                <InputLabel>Megye</InputLabel>
                                                <Select
                                                    value={values?.region?.name || ''}
                                                    label="Megye"
                                                    name="region.name"
                                                    disabled
                                                >
                                                    {getRegions
                                                        .map((region: IRegion) => region.name)
                                                        .map((regionName: string, index) => {
                                                            return (
                                                                <MenuItem key={index} value={regionName}>
                                                                    {regionName}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box display="flex" m={1}>
                                            <Explore
                                                className={styles.icon}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block',
                                                        md: 'block',
                                                        lg: 'block',
                                                        xl: 'block',
                                                    },
                                                }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Irányítószám"
                                                name="zipCode"
                                                value={values.zipCode}
                                                disabled
                                            />
                                        </Box>
                                        <Box display="flex" m={1}>
                                            <LocationCity
                                                className={styles.icon}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block',
                                                        md: 'block',
                                                        lg: 'block',
                                                        xl: 'block',
                                                    },
                                                }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Város"
                                                name="town"
                                                value={values.town}
                                                disabled
                                            />
                                        </Box>
                                        <Box display="flex" m={1}>
                                            <Home
                                                className={styles.icon}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block',
                                                        md: 'block',
                                                        lg: 'block',
                                                        xl: 'block',
                                                    },
                                                }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Cím"
                                                name="address"
                                                value={values.address}
                                                disabled
                                            />
                                        </Box>
                                        <Box display="flex" m={1}>
                                            <EmailOutlined
                                                className={styles.icon}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block',
                                                        md: 'block',
                                                        lg: 'block',
                                                        xl: 'block',
                                                    },
                                                }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Email"
                                                name="email"
                                                value={values.email}
                                                disabled
                                            />
                                        </Box>
                                        <Box display="flex" m={1}>
                                            <AccountBalanceOutlined
                                                className={styles.icon}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block',
                                                        md: 'block',
                                                        lg: 'block',
                                                        xl: 'block',
                                                    },
                                                }}
                                            />
                                            <TextField
                                                error={Boolean(touched?.accountNumber && errors?.accountNumber)}
                                                fullWidth
                                                variant="outlined"
                                                helperText={touched?.accountNumber && errors?.accountNumber}
                                                label="Számlaszám"
                                                name="accountNumber"
                                                placeholder="xxxxxxxx-xxxxxxxx-xxxxxxxx"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.accountNumber}
                                            />
                                        </Box>
                                        <Box display="flex" m={1}>
                                            <ContactPageOutlined
                                                className={styles.icon}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'block',
                                                        md: 'block',
                                                        lg: 'block',
                                                        xl: 'block',
                                                    },
                                                }}
                                            />
                                            <TextField
                                                error={Boolean(touched?.beneficiaryName && errors?.beneficiaryName)}
                                                fullWidth
                                                variant="outlined"
                                                helperText={touched?.beneficiaryName && errors?.beneficiaryName}
                                                label="Kedvezményezett neve"
                                                name="beneficiaryName"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.beneficiaryName}
                                            />
                                        </Box>
                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <Button
                                                disabled={!isValid}
                                                color="primary"
                                                variant="contained"
                                                type="submit"
                                            >
                                                Számlázási adatok mentése
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}
            <AlertDialog
                onYesClick={saveBankTransferDetails}
                content={'Biztosan mented az számlázási adatokat?'}
                isOpen={isAlertDialogOpen}
                setOpen={setAlertDialogOpen}
            />
        </Container>
    );
};

export default StoreInfoEditor;
