export enum FurnitureType {
    WardrobeRow,
    OpeningDoorWardrobe,
    SlidingDoorWardrobe,
    Sideboard,
    Table,
    DiningChair,
    BarStool,
    SwivelChair,
    KitchenCorner,
    Armchair,
    Bed,
    Mattress,
    ConferenceChair,
    DoubleBed,
    CornerSeatL,
    CornerSeatU,
    Couch,
    BoxspringBed,
    BedWithCot,
    SofaSet,
    BabyCot,
    Recumbent,
    Pouffe,
    LoungeBed,
    HallwayCloset,
    ShoeStorage,
    ChestOfDrawers,
    BedLinenHolder,
    SmokingTable,
    Desk,
    TvStand,
    Shelf,
    // DressingTable,
    Nightstand,
    OtherAccessories,
}

export const FurnitureTypeLabel = new Map<number, string>([
    [FurnitureType.WardrobeRow, 'Szekrénysor'],
    [FurnitureType.OpeningDoorWardrobe, 'Nyílóajtós gardrób'],
    [FurnitureType.SlidingDoorWardrobe, 'Tolóajtós gardrób'],
    [FurnitureType.Sideboard, 'Tálaló'],
    [FurnitureType.Table, 'Asztal'],
    [FurnitureType.DiningChair, 'Étkező szék'],
    [FurnitureType.BarStool, 'Bárszék'],
    [FurnitureType.SwivelChair, 'Forgószék'],
    [FurnitureType.KitchenCorner, 'Konyhai sarok'],
    [FurnitureType.Armchair, 'Fotel'],
    [FurnitureType.Bed, 'Ágy'],
    [FurnitureType.Mattress, 'Matrac'],
    [FurnitureType.ConferenceChair, 'Tárgyalószék'],
    [FurnitureType.DoubleBed, 'Klasszikus franciaágy'],
    [FurnitureType.CornerSeatL, 'Sarokülő - L'],
    [FurnitureType.CornerSeatU, 'Sarokülő - U'],
    [FurnitureType.Couch, 'Kanapé'],
    [FurnitureType.BoxspringBed, 'Boxspring ágy'],
    [FurnitureType.BedWithCot, 'Ágyrácsos ágy'],
    [FurnitureType.SofaSet, 'Ülőgarnitúra'],
    [FurnitureType.BabyCot, 'Gyerekágy'],
    [FurnitureType.Recumbent, 'Heverő'],
    [FurnitureType.Pouffe, 'Puff'],
    [FurnitureType.LoungeBed, 'Pihenőágy'],
    [FurnitureType.HallwayCloset, 'Előszobaszekrény'],
    [FurnitureType.ShoeStorage, 'Cipőtároló'],
    [FurnitureType.ChestOfDrawers, 'Komód'],
    [FurnitureType.BedLinenHolder, 'Ágyneműtartó'],
    [FurnitureType.SmokingTable, 'Dohányzóasztal'],
    [FurnitureType.Desk, 'Számítógépasztal - íróasztal'],
    [FurnitureType.TvStand, 'Tv állvány'],
    [FurnitureType.Shelf, 'Polc - térelválasztó'],
    // [FurnitureType.DressingTable, 'Fésülködőasztal'],
    [FurnitureType.Nightstand, 'Éjjeliszekrény'],
    [FurnitureType.OtherAccessories, 'Egyéb kiegészítő'],
]);
