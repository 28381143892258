import { DeleteOutline, ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Theme,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Grid,
    SelectChangeEvent,
} from '@mui/material';
import { RootStoreContext } from 'mobx/rootStore';
import { RegionDeliveryPrice } from 'models/store';
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';
import { areIntervalsIntersecting } from 'utils/areIntervalsIntersecting';
import { observer } from 'mobx-react';
import agent from 'api/agent';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

export const RegionDeliveryPrices: React.FC = ({}) => {
    const { getRegions, regions } = useContext(RootStoreContext).regionStore;
    const { selectedStore, selectedStoreId } = useContext(RootStoreContext).storeStore;
    const [lowerLimitInString, setLowerLimit] = useState<string>();
    const [upperLimitInString, setUpperLimit] = useState<string>();
    const [deliveryPriceInString, setDeliveryPrice] = useState<string>();
    const [regionId, setRegionId] = useState<string | null>(null);
    const [regionDeliveryPrices, setRegionDeliveryPrices] = useState<RegionDeliveryPrice[]>([]);

    const styles = useStyles();

    useEffect(() => {
        if (selectedStore) {
            setRegionDeliveryPrices(selectedStore.regionDeliveryPrices || []);
        }
    }, [selectedStore]);

    const saveDeliveryPricesHandler = () => {
        agent.Store.updateDeliveryPrices(selectedStoreId, regionDeliveryPrices)
            .then(() => showSuccessToast('Szállítási díjak elmentve!'))
            .catch(() => showErrorToast('Szállítási díjak mentése sikertelen!'));
    };

    const removeRegionDeliveryPrice = (id: string) => {
        const regionDeliveryPriceToRemoveIdx = regionDeliveryPrices.findIndex((x) => x.id == id);
        setRegionDeliveryPrices(regionDeliveryPrices.filter((_, idx) => idx !== regionDeliveryPriceToRemoveIdx));
    };

    const addRegionDeliveryPrice = () => {
        if (!regionId) {
            showErrorToast('Válassz vármegyét!');
            return;
        }

        if (lowerLimitInString == undefined) {
            showErrorToast('Töltsd ki az alsó határt!');
            return;
        }

        if (deliveryPriceInString == undefined) {
            showErrorToast('Töltsd ki az árat!');
            return;
        }

        const lowerLimit = +lowerLimitInString;
        const upperLimit = upperLimitInString === undefined ? null : +upperLimitInString;
        const deliveryPrice = +deliveryPriceInString;

        if (lowerLimit < 0) {
            showErrorToast('Az alsó határ nem lehet kisebb nullánál!');
            return;
        }

        if (upperLimit <= lowerLimit && upperLimitInString) {
            showErrorToast('A felső határnak nagyobbnak kell lennie az alsó határnál!');
            return;
        }

        if (deliveryPrice < 0) {
            showErrorToast('A kiszállítási díj nem lehet kisebb nullánál!');
            return;
        }

        const givenRegionDeliveryPrices = regionDeliveryPrices.filter((x) => x.regionId == regionId);
        if (
            givenRegionDeliveryPrices.some((x) =>
                areIntervalsIntersecting(x.lowerLimit, x.upperLimit, lowerLimit, upperLimit),
            )
        ) {
            showErrorToast(
                'Ütköznek a végösszeghatárok ennél a megyénél, már van ebben az ársávban szállítási díj megadva!',
            );
            return;
        }

        setRegionDeliveryPrices(
            [...regionDeliveryPrices, new RegionDeliveryPrice(regionId, lowerLimit, upperLimit, deliveryPrice)].sort(
                (a, b) => {
                    const regionComparison = regions.get(a.regionId).name.localeCompare(regions.get(b.regionId).name);
                    if (regionComparison !== 0) return regionComparison;

                    return a.lowerLimit - b.lowerLimit;
                },
            ),
        );
    };

    return (
        <Accordion sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Szállítási díjak beállítása</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box m={1}>
                    <Alert severity="info">
                        Kérjük adja meg vármegyénként a kiszállítás összegét! <br />A végösszeghatár alsó határát
                        kötelező kitölteni (akár 0-val is lehet). A felső határ kitöltése nem kötelező. <br /> Abban az
                        esetben, ha nem tölti ki a felső határt, akkor az alsó határtól felfelé végtelen összegig
                        érvényes lesz a beállított szállítási díj. <br /> Amennyiben ingyen szeretne szállítani az adott
                        vármegyébe és ársávba, akkor a vármegye és az ársáv beállítása után írjon be 0-t az árhoz.{' '}
                        <br /> Ha nem szállít ki az adott vármegyében, nem kell beállítania semmit az adott vármegyére
                        vonatkozóan. <br /> Ha egyáltalán nem vállal házhozszállítást egyik vármegyére sem, nincs
                        teendője.
                    </Alert>
                </Box>
                <Grid container spacing={2} p={1}>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <FormControl variant="outlined" className={styles.formControl} fullWidth>
                            <InputLabel>Vármegye</InputLabel>
                            <Select
                                label="Vármegye"
                                name="region"
                                value={regionId}
                                onChange={(event: SelectChangeEvent) => {
                                    setRegionId(event.target.value);
                                }}
                            >
                                <MenuItem value={null}>Kérjük válassz...</MenuItem>
                                {getRegions?.map((region) => (
                                    <MenuItem key={region.id} value={region.id}>
                                        {region.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={4} spacing={1}>
                        <Grid item md={4} display="flex" alignItems="center">
                            <Typography>Végösszeg:</Typography>
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                label="Alsó határ (Ft)"
                                variant="outlined"
                                value={lowerLimitInString}
                                onChange={(e) => {
                                    setLowerLimit(e.target.value);
                                }}
                                fullWidth
                                type="text"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                label="Felső határ (Ft)"
                                variant="outlined"
                                onChange={(e) => {
                                    setUpperLimit(e.target.value);
                                }}
                                value={upperLimitInString}
                                fullWidth
                                type="text"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <TextField
                            label="Ár (Ft)"
                            variant="outlined"
                            value={deliveryPriceInString}
                            onChange={(e) => {
                                setDeliveryPrice(e.target.value);
                            }}
                            fullWidth
                            type="text"
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="center" m={1} mt={2}>
                    <Box display="flex" alignItems="center">
                        <Button variant="contained" color="primary" onClick={addRegionDeliveryPrice}>
                            Hozzáadás
                        </Button>
                    </Box>
                </Box>
                {regionDeliveryPrices?.length > 0 && (
                    <Box display="flex" justifyContent="center" m={1} mt={2}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650, marginTop: 1 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Vármegye</TableCell>
                                        <TableCell align="center">Alsó határ (Ft)</TableCell>
                                        <TableCell align="center">Felső határ (Ft)</TableCell>
                                        <TableCell align="center">Ár (Ft)</TableCell>
                                        <TableCell align="center">Törlés</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {regionDeliveryPrices.map(
                                        (regionDeliveryPrice: RegionDeliveryPrice, key: number) => (
                                            <TableRow
                                                key={key}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">
                                                    {regions.get(regionDeliveryPrice.regionId).name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {regionDeliveryPrice.lowerLimit} Ft
                                                </TableCell>
                                                <TableCell align="center">
                                                    {regionDeliveryPrice.upperLimit ? (
                                                        `${regionDeliveryPrice.upperLimit} Ft`
                                                    ) : (
                                                        <>&infin; Ft</>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">{regionDeliveryPrice.price} Ft</TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        component="span"
                                                        onClick={() =>
                                                            removeRegionDeliveryPrice(regionDeliveryPrice.id)
                                                        }
                                                    >
                                                        <DeleteOutline />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ),
                                    )}
                                </TableBody>
                            </Table>
                            <Box display="flex" justifyContent="center" m={1} mt={2}>
                                <Box display="flex" alignItems="center">
                                    <Button variant="contained" color="primary" onClick={saveDeliveryPricesHandler}>
                                        Mentés
                                    </Button>
                                </Box>
                            </Box>
                        </TableContainer>
                    </Box>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default observer(RegionDeliveryPrices);
