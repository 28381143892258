import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from 'mobx/rootStore';
import { Formik } from 'formik';
import { IShop } from 'models/store';
import agent from 'api/agent';
import { makeStyles } from '@mui/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';
import {
    AccessAlarmOutlined,
    EmailOutlined,
    ExpandMore,
    Explore,
    Home,
    LocationCity,
    PersonOutlined,
    PhoneOutlined,
    Public,
} from '@mui/icons-material';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';

const useStyles = makeStyles(() => ({
    icon: {
        marginTop: 15,
        fontSize: 30,
        marginRight: 10,
    },
    formControl: {
        minWidth: 120,
    },
}));

const ShopInfoEditor: React.FC = () => {
    const styles = useStyles();
    const [isLoading, setLoading] = useState(false);
    const [shops, setShops] = useState<IShop[]>();

    useEffect(() => {
        setLoading(true);
        agent.Shop.getAllShopByStoreId()
            .then((shops) => setShops(shops))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleSaveShop = (shop: IShop, setSubmitting: (isSubmitting: boolean) => void) => {
        setLoading(true);

        agent.Shop.updateShopByStoreAdmin(shop)
            .then(() => {
                showSuccessToast(`${shop.name} sikeresen elmentve!`);
                setLoading(true);
                agent.Shop.getAllShopByStoreId()
                    .then((shops) => setShops(shops))
                    .finally(() => setLoading(false));
            })
            .catch(() => {
                showErrorToast(`${shop.name} mentése sikertelen!`);
                setLoading(false);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    if (isLoading || !shops) {
        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
            <CircularProgress />
        </Box>;
    }

    return (
        <Accordion sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Ajánlatoknál megjelenített Üzleteim</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {shops?.map((shop, idx) => (
                    <div key={idx}>
                        <Typography sx={{ textAlign: 'center' }}>{`Bolt ${idx + 1}`}</Typography>

                        <Formik
                            key={shop.id}
                            initialValues={{
                                ...shop,
                            }}
                            enableReinitialize
                            onSubmit={(values, { setSubmitting }) => {
                                handleSaveShop(values, setSubmitting);
                            }}
                        >
                            {({ handleBlur, handleChange, handleSubmit, values, isSubmitting }) => (
                                <form onSubmit={handleSubmit} noValidate>
                                    <Box display="flex" m={1}>
                                        <PersonOutlined
                                            className={styles.icon}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'block',
                                                    md: 'block',
                                                    lg: 'block',
                                                    xl: 'block',
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Név"
                                            value={values?.name}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box display="flex" m={1}>
                                        <Public
                                            className={styles.icon}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'block',
                                                    md: 'block',
                                                    lg: 'block',
                                                    xl: 'block',
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Megye"
                                            value={values?.region?.name}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box display="flex" m={1}>
                                        <Explore
                                            className={styles.icon}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'block',
                                                    md: 'block',
                                                    lg: 'block',
                                                    xl: 'block',
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Irányítószám"
                                            value={values.zipCode}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box display="flex" m={1}>
                                        <LocationCity
                                            className={styles.icon}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'block',
                                                    md: 'block',
                                                    lg: 'block',
                                                    xl: 'block',
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Város"
                                            value={values.town}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box display="flex" m={1}>
                                        <Home
                                            className={styles.icon}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'block',
                                                    md: 'block',
                                                    lg: 'block',
                                                    xl: 'block',
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Cím"
                                            name="address"
                                            value={values.address}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box display="flex" m={1}>
                                        <PhoneOutlined
                                            className={styles.icon}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'block',
                                                    md: 'block',
                                                    lg: 'block',
                                                    xl: 'block',
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Telefonszám"
                                            name="phoneNumber"
                                            value={values.phoneNumber}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box display="flex" m={1}>
                                        <EmailOutlined
                                            className={styles.icon}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'block',
                                                    md: 'block',
                                                    lg: 'block',
                                                    xl: 'block',
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Ügyfél kommunikációs e-mail"
                                            name="email"
                                            value={values.emailForCommunication}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box display="flex" m={1}>
                                        <EmailOutlined
                                            className={styles.icon}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'block',
                                                    md: 'block',
                                                    lg: 'block',
                                                    xl: 'block',
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Térképes e-mail"
                                            name="email"
                                            value={values.emailForMap || ''}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box display="flex" m={1}>
                                        <AccessAlarmOutlined
                                            className={styles.icon}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'block',
                                                    md: 'block',
                                                    lg: 'block',
                                                    xl: 'block',
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Nyitvatartás"
                                            name="businessHours"
                                            value={values.businessHours}
                                            disabled={true}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="center" m={1}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.isShowableOnMap}
                                                    name="isShowableOnMap"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    color="primary"
                                                />
                                            }
                                            label="Megjelenés térképen"
                                        />
                                        <Box display="flex" justifyContent="center" m={1}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.isOfferable}
                                                        name="isOfferable"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        color="primary"
                                                    />
                                                }
                                                label="Megjelenés az ajánlatok között"
                                            />
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="center" mt={2} mb={2}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {`Bolt ${idx + 1} Mentése`}
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </div>
                ))}
            </AccordionDetails>
        </Accordion>
    );
};

export default ShopInfoEditor;
