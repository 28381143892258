import { action, makeObservable, observable, computed } from 'mobx';
import { RootStore } from 'mobx/rootStore';
import { IMarginWarning } from 'models/margin';

export default class MidibiFurnitureManagerStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable marginWarnings: IMarginWarning[] = [];

    @computed get getmarginWarnings(): IMarginWarning[] {
        return this.marginWarnings;
    }

    @computed get getmarginWarningCount(): number {
        return this.marginWarnings.length;
    }

    @action addMarginWarning = (marginWarning: IMarginWarning): void => {
        this.marginWarnings.push(marginWarning);
    };

    @action clearMarginWarnings = (): void => {
        this.marginWarnings = [];
    };
}
