import agent from 'api/agent';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { ColorLayer, ColorLayerOptionImage, IColorLayer } from 'models/colorLayer';
import { EditorType } from 'models/enums/editorType';
import { FeatureOptions, IFurniture, OptionImage } from 'models/furniture';
import { IMiloColorLayer, MiloColorEditor, MiloColorLayer, MiloOptionImage } from 'models/furnitureEditor';
import { FloorColorLayerFeatureCode, WallColorLayerFeatureCode } from 'utils/appConstants';
import { RootStore } from './rootStore';

export default class FurnitureEditorMiloStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;
    }

    @observable selectedFurniture: IFurniture | null = null;

    @computed get getSelectedMiloFurniture(): IFurniture {
        return this.selectedFurniture;
    }

    @action loadSelectedMiloFurniture = async (furnitureId: string): Promise<void> => {
        if (!furnitureId) {
            throw new Error('furnitureId was undefined!');
        }

        const furniture = await agent.FurnitureEditor.getFurnitureWithMiloColorEditorById(furnitureId);
        this.selectedFurniture = furniture;
    };

    @action checkMiloColorEditor = (): void => {
        const miloColorEditor = this.selectedFurniture.miloColorEditor || new MiloColorEditor();

        const featuresOfFurniture = this.selectedFurniture.furnitureFeatureOptions.map((ffo) =>
            this.rootStore.featureStore.getLocalFeatureById(ffo.featureId),
        );

        const baseFeatures = featuresOfFurniture.filter(
            (feature) =>
                feature.featureCode === WallColorLayerFeatureCode || feature.featureCode === FloorColorLayerFeatureCode,
        );

        if (!(miloColorEditor.baseColorLayers && miloColorEditor.baseColorLayers.length > 0)) {
            miloColorEditor.baseColorLayers = baseFeatures.map((f) => new ColorLayer(f.name, f.id));
        }

        if (!(miloColorEditor.miloColorLayers && miloColorEditor.miloColorLayers.length > 0)) {
            const miloFeatures = featuresOfFurniture.filter((feature) => !baseFeatures.includes(feature));

            miloColorEditor.miloColorLayers = miloFeatures.map((f) => new MiloColorLayer(f.name, f.id));
        }

        this.selectedFurniture.miloColorEditor = miloColorEditor;
    };

    @action setButtonPosXOnColorLayer = (buttonPosX: number, featureId: string): void => {
        const searchedColorLayer = this.findColorLayer(featureId);

        if (searchedColorLayer) {
            searchedColorLayer.buttonPosX = buttonPosX;
        }
    };

    @action setButtonPosYOnColorLayer = (buttonPosY: number, featureId: string): void => {
        const searchedColorLayer = this.findColorLayer(featureId);

        if (searchedColorLayer) {
            searchedColorLayer.buttonPosY = buttonPosY;
        }
    };

    @action setSvgData = (svgData: string, featureId: string): void => {
        // We can convert it to IColorLayer as MiloColorLayer does not have SVG.
        const searchedBaseColorLayer = this.findColorLayer(featureId) as IColorLayer;

        if (searchedBaseColorLayer) {
            searchedBaseColorLayer.layerSvg = svgData;
        }

        // Force UI render
        this.selectedFurniture = { ...this.selectedFurniture };
    };

    // Duplicated to furnitureStore's addFeatureOption
    @action addFeatureOption = (featureId: string, optionId: string): void => {
        const featureOption = this.selectedFurniture?.furnitureFeatureOptions?.find(
            (ffo) => ffo.featureId === featureId,
        );

        if (!featureOption) {
            const newFeatureOption = new FeatureOptions(featureId, EditorType.ColorEditor);
            newFeatureOption.options.push(new OptionImage(optionId));
            this.selectedFurniture?.furnitureFeatureOptions.push(newFeatureOption);
        } else {
            featureOption.options.push(new OptionImage(optionId));
        }
    };

    // Duplicated to furnitureStore's removeFeatureOption
    @action removeFeatureOption = (featureId: string, optionId: string): void => {
        const featureOption = this.selectedFurniture?.furnitureFeatureOptions?.find(
            (ffo) => ffo.featureId === featureId,
        );

        featureOption.options = featureOption.options.filter((optionImage) => optionImage.optionId !== optionId);
    };

    @action setColorLayerOptionImage = (optionId: string, featureId: string, colorSampleUrl: string): void => {
        const baseColorLayer = this.selectedFurniture.miloColorEditor.baseColorLayers.find(
            (bcl) => bcl.featureId === featureId,
        );

        if (baseColorLayer) {
            const optionImage = baseColorLayer.optionImages?.find((oi) => oi.optionId === optionId);

            if (optionImage) {
                optionImage.colorSampleImageUrl = colorSampleUrl;
            } else {
                if (!baseColorLayer.optionImages) {
                    baseColorLayer.optionImages = [];
                }

                baseColorLayer.optionImages.push(new ColorLayerOptionImage(optionId, featureId, colorSampleUrl));
            }

            return;
        }

        const miloColorLayer = this.selectedFurniture.miloColorEditor.miloColorLayers.find(
            (mcl) => mcl.featureId === featureId,
        );

        if (miloColorLayer) {
            const optionImage = miloColorLayer.miloOptionImages.find((moi) => moi.optionId === optionId);

            if (optionImage) {
                optionImage.colorSampleImageUrl = colorSampleUrl;
            } else {
                if (!miloColorLayer.miloOptionImages) {
                    miloColorLayer.miloOptionImages = [];
                }

                miloColorLayer.miloOptionImages.push(new MiloOptionImage(optionId, featureId, colorSampleUrl));
            }
        }
    };

    @action saveMiloColorEditor = (): Promise<any> => {
        return agent.FurnitureEditor.upsertMiloColorEditor(
            this.selectedFurniture.id,
            this.selectedFurniture.miloColorEditor,
        );
    };

    findColorLayer = (featureId: string): IColorLayer | IMiloColorLayer => {
        const baseColorLayer = this.selectedFurniture.miloColorEditor.baseColorLayers.find(
            (bcl) => bcl.featureId === featureId,
        );

        if (baseColorLayer) {
            return baseColorLayer;
        }

        return this.selectedFurniture.miloColorEditor.miloColorLayers.find((mcl) => mcl.featureId === featureId);
    };
}
