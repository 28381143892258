export interface IDropzone {
    id: string;
    lineNumber: number;
    posX: number;
    posY: number;
    height: number;
    width: number;
    featureIds: string[];
    nearbyDropzoneLineNumbers: number[];
    containedFeatureId?: string | null;
}

export class Dropzone implements IDropzone {
    id: string;
    lineNumber: number;
    posX = 5;
    posY = 5;
    height = 7;
    width = 7;
    featureIds: string[] = [];
    nearbyDropzoneLineNumbers: number[] = [];
    containedFeatureId?: string | null;

    constructor(lineNumber: number) {
        this.lineNumber = lineNumber;
    }
}
