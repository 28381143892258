import { Box, Card, CircularProgress, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IAnalytics } from 'models/analytics';
import React from 'react';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, AreaChart, Area } from 'recharts';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    label: {
        marginLeft: theme.spacing(1),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        height: 48,
        width: 48,
    },
}));

interface IViewTrendsProps {
    isLoading: boolean;
    analytics: IAnalytics;
}

const ViewTrends: React.FC<IViewTrendsProps> = ({ isLoading, analytics }) => {
    const styles = useStyles();

    return (
        <Box mt={3}>
            <Card className={styles.root}>
                <Box flexGrow={1}>
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box>
                            <Typography component="h3" gutterBottom variant="overline" color="textSecondary">
                                Elmúlt fél év oldal megtekintések száma
                            </Typography>
                            <Box mt={2}>
                                <ResponsiveContainer width="100%" height={200}>
                                    <AreaChart
                                        width={500}
                                        height={200}
                                        data={analytics?.viewTrends || []}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Area type="monotone" dataKey="value" stroke="#d1d0cf" fill="#d1d0cf" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

export default ViewTrends;
