import agent from 'api/agent';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { ColorLayer, ColorLayerOptionImage, DoorSvg, IColorLayer } from 'models/colorLayer';
import { Dropzone, IDropzone } from 'models/dropzone';
import { EditorType } from 'models/enums/editorType';
import { IFeature } from 'models/feature';
import { FeatureOptions, IFeatureOptions, IFurniture, OptionImage } from 'models/furniture';
import { FeatureBasicImage, FurnitureEditor, IFurnitureEditor } from 'models/furnitureEditor';
import { BasicImage, IBasicImage } from 'models/image';
import { DivianShortenedCompanyName, FloorColorLayerName, WallColorLayerName } from 'utils/appConstants';
import FurnitureEditorBiroStore from './furnitureEditorBiroStore';
import { RootStore } from './rootStore';

export default class FurnitureEditorDivianStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;
    }

    @observable selectedFurniture: IFurniture | null = null;
    @observable corpusVariantData: { optionCode: string; corpusVariant: string }[] = [];
    @observable furnitureFeatures: Map<string, IFeature> = new Map();

    @computed get getSelectedDivianFurniture(): IFurniture {
        return this.selectedFurniture;
    }

    @computed get getFurnitureEditors(): IFurnitureEditor[] {
        return this.selectedFurniture?.furnitureEditors || [];
    }

    @computed get getUniqueEditorsData(): IFeatureOptions[] {
        return this.selectedFurniture?.furnitureFeatureOptions?.filter((ffo) => ffo.editorType === EditorType.Unique);
    }

    @computed get getAdditionalEquipmentData(): IFeatureOptions[] {
        return this.selectedFurniture?.furnitureFeatureOptions?.filter(
            (ffo) => ffo.editorType === EditorType.AdditionalEquipment,
        );
    }

    @computed get getColorEditorData(): IFeatureOptions[] {
        return this.selectedFurniture?.furnitureFeatureOptions?.filter(
            (ffo) => ffo.editorType === EditorType.FurnitureEditor,
        );
    }

    @computed get getFurnitureEditorData(): IFeatureOptions[] {
        return this.selectedFurniture?.furnitureFeatureOptions?.filter(
            (ffo) => ffo.editorType === EditorType.ColorEditor,
        );
    }

    @action loadSelectedDivianFurniture = async (furnitureId: string): Promise<void> => {
        if (!furnitureId) {
            throw new Error('furnitureId was undefined!');
        }

        const furniture = await agent.FurnitureEditor.getFurnitureWithDivianFurnitureEditorsById(furnitureId);

        furniture.furnitureEditors.forEach((furnitureEditor) => {
            // Divian Padlo
            if (furnitureEditor.colorLayers.every((x) => x.featureId !== 'ed0007cd-5c97-4d94-1c1e-08da18807c71')) {
                furnitureEditor.colorLayers.push(new ColorLayer('Padló', 'ed0007cd-5c97-4d94-1c1e-08da18807c71'));
            }
            // Divian Fal
            if (furnitureEditor.colorLayers.every((x) => x.featureId !== 'd14c7f9e-565c-4869-1c1d-08da18807c71')) {
                furnitureEditor.colorLayers.push(new ColorLayer('Fal', 'd14c7f9e-565c-4869-1c1d-08da18807c71'));
            }
        });

        const colorEditorFeatureOptions = furniture.furnitureFeatureOptions.filter(
            (x) => x.editorType == EditorType.ColorEditor,
        );
        furniture.furnitureEditors.forEach((furnitureEditor) => {
            colorEditorFeatureOptions.forEach((cefo) => {
                if (!furnitureEditor.colorLayers.some((cl) => cl.featureId == cefo.featureId)) {
                    const feature = this.rootStore.featureStore.allFeature.get(cefo.featureId);
                    const furnitureFeatureDisplayName = furniture.featureOptionDisplayNames.find(
                        (fodn) => fodn.code === feature.featureCode,
                    );
                    furnitureEditor.colorLayers.push(
                        new ColorLayer(furnitureFeatureDisplayName?.displayName || feature.name, feature.id),
                    );
                }
            });
        });

        if (furniture.furnitureFeatureOptions.some((x) => x.featureCode === 'TUK_AJT_DB_NUM')) {
            furniture.furnitureEditors.forEach((furnitureEditor) => {
                if (furnitureEditor.baseImgUrlWithMirrors.length === 0) {
                    if (furnitureEditor.name === '1-korpusz') {
                        for (let index = 0; index < 4; index++) {
                            furnitureEditor.baseImgUrlWithMirrors.push(new BasicImage('', index));
                        }
                    } else if (furnitureEditor.name === '2-korpusz') {
                        for (let index = 0; index < 4; index++) {
                            furnitureEditor.baseImgUrlWithMirrors.push(new BasicImage('', index));
                        }
                    } else if (furnitureEditor.name === '3-korpusz') {
                        for (let index = 0; index < 6; index++) {
                            furnitureEditor.baseImgUrlWithMirrors.push(new BasicImage('', index));
                        }
                    } else if (furnitureEditor.name === '4-korpusz') {
                        for (let index = 0; index < 8; index++) {
                            furnitureEditor.baseImgUrlWithMirrors.push(new BasicImage('', index));
                        }
                    }
                }

                furnitureEditor.colorLayers.forEach((colorLayer) => {
                    if (
                        colorLayer.name === 'Ajtó szín' ||
                        colorLayer.name === 'Betét szín' ||
                        colorLayer.name === 'Front betét 1. sor' ||
                        colorLayer.name === 'Front betét 2. sor' ||
                        colorLayer.name === 'Front betét 3. sor' ||
                        colorLayer.name === 'Front betét 4. sor'
                    ) {
                        if (!colorLayer.doorSvgs || colorLayer.doorSvgs.length == 0) {
                            colorLayer.doorSvgs = [];
                            if (furnitureEditor.name === '1-korpusz') {
                                for (let index = 0; index < 2; index++) {
                                    colorLayer.doorSvgs.push(new DoorSvg(index));
                                }
                            } else if (furnitureEditor.name === '2-korpusz') {
                                for (let index = 0; index < 2; index++) {
                                    colorLayer.doorSvgs.push(new DoorSvg(index));
                                }
                            } else if (furnitureEditor.name === '3-korpusz') {
                                for (let index = 0; index < 3; index++) {
                                    colorLayer.doorSvgs.push(new DoorSvg(index));
                                }
                            } else if (furnitureEditor.name === '4-korpusz') {
                                for (let index = 0; index < 4; index++) {
                                    colorLayer.doorSvgs.push(new DoorSvg(index));
                                }
                            }
                        }
                    }
                });
            });
        }

        furniture.furnitureEditors.forEach((furnitureEditor) => {
            furnitureEditor.colorLayers.sort((a, b) => a.name.localeCompare(b.name));
        });

        this.selectedFurniture = furniture;
    };

    @action loadCorpusVariantData = (): void => {
        this.corpusVariantData = [];
        this.selectedFurniture.furnitureEditors.forEach((furnitureEditor) => {
            furnitureEditor.assignedSizes.forEach((size) => {
                this.addCorpusVariant(size, furnitureEditor.name);
            });
        });
    };

    @action addCorpusVariant = (optionCode: string, corpusVariant: string): void => {
        const corpusVariantDataElement = this.corpusVariantData.find((cv) => cv.optionCode === optionCode);

        if (corpusVariantDataElement) {
            corpusVariantDataElement.corpusVariant = corpusVariant;
        } else {
            this.corpusVariantData.push({ optionCode, corpusVariant });
        }
    };

    @action manageFurnitureEditors = (): void => {
        const uniqueCorpusVariants = this.corpusVariantData
            .map((c) => c.corpusVariant)
            .filter((v, i, a) => a.indexOf(v) === i);

        // Collect deletable Furniture Editors
        const toBeDeletedFurnitureEditors = this.selectedFurniture.furnitureEditors.filter(
            (fe) => !uniqueCorpusVariants.includes(fe.name),
        );

        // Delete Furniture Editors if necesarry
        if (toBeDeletedFurnitureEditors && toBeDeletedFurnitureEditors.length > 0) {
            toBeDeletedFurnitureEditors.forEach((furnitureEditor) => {
                this.selectedFurniture.furnitureEditors = this.selectedFurniture.furnitureEditors.filter(
                    (fe) => fe.name !== furnitureEditor.name,
                );
            });
        }

        uniqueCorpusVariants.forEach((corpusVariant) => {
            const furnitureEditor = this.selectedFurniture.furnitureEditors.find((fe) => fe.name === corpusVariant);
            if (!furnitureEditor) {
                const newFurnitureEditor = new FurnitureEditor();
                newFurnitureEditor.name = corpusVariant;
                newFurnitureEditor.assignedSizes = this.corpusVariantData
                    .filter((cv) => cv.corpusVariant === corpusVariant)
                    .map((cv) => cv.optionCode);
                this.getColorLayersForFurniture().forEach((colorEditorLayer) =>
                    newFurnitureEditor.colorLayers.push(colorEditorLayer),
                );
                this.selectedFurniture.furnitureEditors.push(newFurnitureEditor);
            }
        });

        // copy from loadSelectedDivianFurniture
        if (this.selectedFurniture.furnitureFeatureOptions.some((x) => x.featureCode === 'TUK_AJT_DB_NUM')) {
            this.selectedFurniture.furnitureEditors.forEach((furnitureEditor) => {
                if (furnitureEditor.baseImgUrlWithMirrors.length === 0) {
                    if (furnitureEditor.name === '1-korpusz') {
                        for (let index = 0; index < 4; index++) {
                            furnitureEditor.baseImgUrlWithMirrors.push(new BasicImage('', index));
                        }
                    } else if (furnitureEditor.name === '2-korpusz') {
                        for (let index = 0; index < 4; index++) {
                            furnitureEditor.baseImgUrlWithMirrors.push(new BasicImage('', index));
                        }
                    } else if (furnitureEditor.name === '3-korpusz') {
                        for (let index = 0; index < 6; index++) {
                            furnitureEditor.baseImgUrlWithMirrors.push(new BasicImage('', index));
                        }
                    } else if (furnitureEditor.name === '4-korpusz') {
                        for (let index = 0; index < 8; index++) {
                            furnitureEditor.baseImgUrlWithMirrors.push(new BasicImage('', index));
                        }
                    }
                }

                furnitureEditor.colorLayers.forEach((colorLayer) => {
                    if (
                        colorLayer.name === 'Ajtó szín' ||
                        colorLayer.name === 'Betét szín' ||
                        colorLayer.name === 'Front betét 1. sor' ||
                        colorLayer.name === 'Front betét 2. sor' ||
                        colorLayer.name === 'Front betét 3. sor' ||
                        colorLayer.name === 'Front betét 4. sor'
                    ) {
                        if (!colorLayer.doorSvgs || colorLayer.doorSvgs.length == 0) {
                            colorLayer.doorSvgs = [];
                            if (furnitureEditor.name === '1-korpusz') {
                                for (let index = 0; index < 2; index++) {
                                    colorLayer.doorSvgs.push(new DoorSvg(index));
                                }
                            } else if (furnitureEditor.name === '2-korpusz') {
                                for (let index = 0; index < 2; index++) {
                                    colorLayer.doorSvgs.push(new DoorSvg(index));
                                }
                            } else if (furnitureEditor.name === '3-korpusz') {
                                for (let index = 0; index < 3; index++) {
                                    colorLayer.doorSvgs.push(new DoorSvg(index));
                                }
                            } else if (furnitureEditor.name === '4-korpusz') {
                                for (let index = 0; index < 4; index++) {
                                    colorLayer.doorSvgs.push(new DoorSvg(index));
                                }
                            }
                        }
                    }
                });
            });
        }

        this.selectedFurniture.furnitureEditors.forEach((furnitureEditor) => {
            furnitureEditor.colorLayers.sort((a, b) => a.name.localeCompare(b.name));
        });

        //copy end
    };

    @action loadFurnitureFeatures = async (selectedFurnitureId: string): Promise<void> => {
        const features = await agent.Feature.getFeaturesWithDisplayNameByFurnitureId(selectedFurnitureId);
        runInAction(() => {
            this.furnitureFeatures.clear();
            features.forEach((feature: IFeature) => {
                this.furnitureFeatures.set(feature.id, feature);
            });
        });
    };

    @action setFurnitureEditorFeatureImage = (
        furnitureEditorName: string,
        featureId: string,
        image: IBasicImage,
    ): void => {
        const furnitureEditor = this.selectedFurniture.furnitureEditors.find((f) => f.name === furnitureEditorName);
        const featureBasicImage = furnitureEditor.featureBasicImages.find((fbi) => fbi.featureId === featureId);

        if (featureBasicImage) {
            featureBasicImage.basicImage.link == image.link;
        } else {
            furnitureEditor.featureBasicImages.push(new FeatureBasicImage(featureId, image));
        }
    };

    @action deleteFurnitureEditorFeatureImage = (furnitureEditorName: string, featureId: string): void => {
        const furnitureEditor = this.selectedFurniture.furnitureEditors.find((f) => f.name === furnitureEditorName);

        furnitureEditor.featureBasicImages = furnitureEditor.featureBasicImages.filter(
            (fbi) => fbi.featureId !== featureId,
        );
    };

    @action addDropzone = (furnitureEditorName: string): IDropzone[] => {
        const furnitureEditor = this.selectedFurniture.furnitureEditors.find((fe) => fe.name === furnitureEditorName);

        if (!furnitureEditor.dropzones) {
            furnitureEditor.dropzones = [new Dropzone(1)];
        } else {
            const lineNumbers = furnitureEditor.dropzones.map((dz) => dz.lineNumber);
            const lastDropzoneLineNumber = lineNumbers && lineNumbers.length > 0 ? Math.max(...lineNumbers) : 0;
            furnitureEditor.dropzones = [...furnitureEditor.dropzones, new Dropzone(lastDropzoneLineNumber + 1)];
        }

        // Yes, its a hack due to mobx not refreshing observables..
        return [...furnitureEditor.dropzones];
    };

    @action deleteDropzone = (furnitureEditorName: string, lineNumber: number): IDropzone[] => {
        const furnitureEditor = this.selectedFurniture.furnitureEditors.find((fe) => fe.name === furnitureEditorName);
        furnitureEditor.dropzones = furnitureEditor.dropzones.filter((dz) => dz.lineNumber !== lineNumber);

        // Yes, its a hack due to mobx not refreshing observables..
        return [...furnitureEditor.dropzones];
    };

    @action saveDropzone = (furnitureEditorName: string, dropzone: IDropzone): void => {
        const furnitureEditor = this.selectedFurniture.furnitureEditors.find((fe) => fe.name === furnitureEditorName);
        const indexOfDropzone = furnitureEditor.dropzones.findIndex((dz) => dz.lineNumber === dropzone.lineNumber);
        furnitureEditor.dropzones[indexOfDropzone] = dropzone;
    };

    @action setColorLayerOptionImage = (
        furnitureEditorName: string,
        colorLayerName: string,
        optionId: string,
        featureId: string,
        colorSampleUrl: string,
    ): void => {
        const furnitureEditor = this.selectedFurniture?.furnitureEditors?.find((fe) => fe.name === furnitureEditorName);
        const colorLayer = furnitureEditor?.colorLayers?.find((cl) => cl.name === colorLayerName);

        const optionImage = colorLayer?.optionImages?.find(
            (oi) => oi.optionId === optionId && oi.featureId === featureId,
        );

        if (!optionImage) {
            if (!colorLayer?.optionImages) {
                colorLayer.optionImages = [];
            }

            colorLayer.optionImages.push(new ColorLayerOptionImage(optionId, featureId, colorSampleUrl));
            return;
        }

        optionImage.colorSampleImageUrl = colorSampleUrl;
    };

    @action setColorEditorBaseImage = (furnitureEditorName: string, imageUrl: string): void => {
        const furnitureEditor = this.selectedFurniture?.furnitureEditors?.find((fe) => fe.name === furnitureEditorName);
        if (furnitureEditor) {
            furnitureEditor.colorEditorBaseImgUrl = imageUrl;
        }
    };

    @action setColorEditorBaseMirrorImage = (
        furnitureEditorName: string,
        mirrorIndex: number,
        imageUrl: string,
    ): void => {
        const furnitureEditor = this.selectedFurniture?.furnitureEditors?.find((fe) => fe.name === furnitureEditorName);
        if (furnitureEditor) {
            const mirrorImage = furnitureEditor.baseImgUrlWithMirrors.find((biuwm) => biuwm.mirrorType == mirrorIndex);
            mirrorImage.link = imageUrl;
        }
    };

    @action setFurnitureEditorBaseImage = (furnitureEditorName: string, imageUrl: string): void => {
        const furnitureEditor = this.selectedFurniture?.furnitureEditors?.find((fe) => fe.name === furnitureEditorName);
        if (furnitureEditor) {
            furnitureEditor.furnitureEditorBaseImgUrl = imageUrl;
        }

        // Force UI render
        this.selectedFurniture = { ...this.selectedFurniture };
    };

    @action setButtonPosXOnColorLayer = (
        furnitureEditorName: string,
        colorLayerName: string,
        buttonPosX: number,
    ): void => {
        this.selectedFurniture.furnitureEditors
            .find((fe) => fe.name === furnitureEditorName)
            .colorLayers.find((cl) => cl.name === colorLayerName).buttonPosX = buttonPosX;
    };

    @action setButtonPosYOnColorLayer = (
        furnitureEditorName: string,
        colorLayerName: string,
        buttonPosY: number,
    ): void => {
        this.selectedFurniture.furnitureEditors
            .find((fe) => fe.name === furnitureEditorName)
            .colorLayers.find((cl) => cl.name === colorLayerName).buttonPosY = buttonPosY;
    };

    @action setSvgData = (furnitureEditorName: string, colorLayerName: string, svgData: string): void => {
        const colorLayer = this.selectedFurniture.furnitureEditors
            .find((fe) => fe.name === furnitureEditorName)
            .colorLayers.find((cl) => cl.name === colorLayerName);

        colorLayer.layerSvg = svgData;

        // Force UI render
        this.selectedFurniture = { ...this.selectedFurniture };
    };

    @action setDoorSvgData = (
        furnitureEditorName: string,
        colorLayerName: string,
        doorIndex: number,
        svgData: string,
    ): void => {
        const doorSvg = this.selectedFurniture.furnitureEditors
            .find((fe) => fe.name === furnitureEditorName)
            .colorLayers.find((cl) => cl.name === colorLayerName)
            .doorSvgs.find((ds) => ds.doorType == doorIndex);

        doorSvg.layerSvg = svgData;

        // Force UI render
        this.selectedFurniture = { ...this.selectedFurniture };
    };

    @action addFeatureOption = (featureId: string, optionId: string): void => {
        const featureOption = this.selectedFurniture?.furnitureFeatureOptions?.find(
            (ffo) => ffo.featureId === featureId,
        );

        if (!featureOption) {
            const newFeatureOption = new FeatureOptions(featureId, EditorType.ColorEditor);
            newFeatureOption.options.push(new OptionImage(optionId));
            this.selectedFurniture?.furnitureFeatureOptions.push(newFeatureOption);
        } else {
            const index = featureOption.options.findIndex((x) => x.optionId == optionId);
            if (index === -1) featureOption.options.push(new OptionImage(optionId));
        }
    };

    @action removeFeatureOption = (featureId: string, optionId: string): void => {
        const featureOption = this.selectedFurniture?.furnitureFeatureOptions?.find(
            (ffo) => ffo.featureId === featureId,
        );

        featureOption.options = featureOption.options.filter((optionImage) => optionImage.optionId !== optionId);
    };

    @action handleColorSampleDisplayForDivian = (
        furnitureEditorName: string,
        featureId: string,
        optionId: string,
        isChecked: boolean,
    ): void => {
        const colorLayer = this.selectedFurniture?.furnitureEditors
            ?.find((x) => x.name === furnitureEditorName)
            ?.colorLayers.find((x) => x.featureId === featureId);

        const colorSample = colorLayer?.optionImages?.find((x) => x.optionId === optionId);

        if (colorSample) {
            colorSample.isDisplayableDivian = isChecked;
        } else {
            colorLayer.optionImages.push(
                new ColorLayerOptionImage(optionId, featureId, '', null, null, null, isChecked),
            );
        }
    };

    // TODO: DELETE IT IF NEW IMAGE SETTING WORKS!!!!
    @action setFeatureOptionImage = (featureId: string, optionId: string, image: IBasicImage): void => {
        const optionImage = this.selectedFurniture?.furnitureFeatureOptions
            ?.find((ffo) => ffo.featureId === featureId)
            ?.options?.find((optionImage) => optionImage.optionId === optionId);

        if (optionImage && optionImage.basicImage) {
            optionImage.basicImage.link = image?.link || null;
        } else {
            optionImage.basicImage = image;
        }
    };

    @action saveDivianFurnitureEditors = (): Promise<any> => {
        return agent.FurnitureEditor.upsertDivianEditors(
            this.selectedFurniture.id,
            this.selectedFurniture.furnitureEditors,
            this.selectedFurniture.furnitureFeatureOptions,
        );
    };

    getLocalFeatureWithDisplayNameById = (featureId: string): IFeature => {
        return this.furnitureFeatures.get(featureId);
    };

    getColorLayersForFurniture = (): IColorLayer[] => {
        const isDivian = this.rootStore.userStore.currentUser.companyShortenedName === DivianShortenedCompanyName;

        let colorLayers = [];
        if (isDivian) {
            colorLayers = [
                new ColorLayer(FloorColorLayerName, this.getFeatureIdByName(FloorColorLayerName)),
                new ColorLayer(WallColorLayerName, this.getFeatureIdByName(WallColorLayerName)),
            ];
        }

        return [
            ...colorLayers,
            ...this.selectedFurniture.furnitureFeatureOptions
                .filter((ffo) => !isDivian || ffo.editorType === EditorType.ColorEditor)
                .map((ffo) => {
                    const feature = this.rootStore.featureStore.allFeature.get(ffo.featureId);
                    const furnitureFeatureDisplayName = this.selectedFurniture.featureOptionDisplayNames.find(
                        (fodn) => fodn.code === feature.featureCode,
                    );
                    return new ColorLayer(furnitureFeatureDisplayName?.displayName || feature.name, feature.id);
                }),
        ];
    };

    getFeatureIdByName = (name: string): string => {
        return this.rootStore.featureStore.getLocalFeatureByName(name).id;
    };
}
