import agent from 'api/agent';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApplicationRole } from 'models/enums/applicationRole';
import { OrderStatus } from 'models/enums/orderStatus';
import { IOrder, IOrderHeader } from 'models/order';
import { RootStore } from './rootStore';

export default class OrderStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable orders: Map<string, IOrderHeader> = new Map();
    @observable selectedOrderId: string;
    @observable selectedOrder: IOrder = null;

    @computed get getOrders(): IOrderHeader[] {
        return Array.from(this.orders.values());
    }

    @computed get getSelectedOrder(): IOrder {
        return this.selectedOrder;
    }

    @action loadOrders = async (): Promise<void> => {
        const userStore = this.rootStore.userStore;
        let orders: IOrderHeader[] = [];

        if (userStore.currentUser.role === ApplicationRole.MidibiAdministrator) {
            orders = await agent.Order.getHeadersOfOrdersByCompanyId(userStore.currentUser.companyId);
        } else {
            orders = await agent.Order.getHeadersOfOrdersByStoreId(userStore.currentUser.storeId);
        }

        this.orders.clear();
        orders.forEach((order: IOrderHeader) => this.orders.set(order.id, order));
    };

    @action setSelectedOrder = (id: string) => {
        this.selectedOrderId = id;
    };

    @action loadSelectedOrder = async (storeId: string): Promise<IOrder> => {
        const order = await agent.Order.getOrderForStoreByIdAndStoreId(this.selectedOrderId, storeId);

        this.selectedOrder = order;

        return order;
    };

    @action setOrderStatus = async (orderStatus: OrderStatus) => {
        await agent.Order.setOrderStatus(this.selectedOrder.id, orderStatus);
        this.loadOrders();
    };

    getOrderHeaderById = (orderHeaderId: string) => {
        return this.orders.get(orderHeaderId);
    };
}
