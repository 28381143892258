import React, { useContext } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RootStoreContext } from 'mobx/rootStore';
import MidibiLogo from 'assets/images/midibi_logo_sidebar.webp';
import { ApplicationRoleLabel } from 'models/enums/applicationRole';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        width: '80%',
        height: 'auto',
    },
    name: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
}));

const Profile: React.FC = () => {
    const classes = useStyles();
    const { currentUser } = useContext(RootStoreContext).userStore;

    if (!currentUser) {
        return null;
    }

    return (
        <Box className={classes.root}>
            <img src={MidibiLogo} className={classes.logo} alt="MIDIBI logo" />
            <Box mt={1.5}>
                <Typography className={classes.name} variant="h4">
                    {currentUser?.name}
                </Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body2">{ApplicationRoleLabel.get(currentUser.role)}</Typography>
            </Box>
        </Box>
    );
};

export default Profile;
