import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import pages from 'layout/components/NavBar/pages';
import { Backdrop, CircularProgress, Theme, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NavBar, TopBar } from './components';
import { useRootStore } from 'mobx/rootStore';
import { useNavigate } from 'react-router-dom';
import { IUser } from 'models/user';
import { ApplicationRole } from 'models/enums/applicationRole';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingTop: 56,
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64,
        },
    },
    shiftContent: {
        paddingLeft: 240,
    },
    content: {
        height: '100%',
    },
}));

type MainLayoutProps = {
    children: React.ReactNode;
};

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const { loadUserDetails, currentUser } = useRootStore().userStore;
    const { loadAdminFrontEndData } = useRootStore().frontEndStore;
    const { loadAllFeature } = useRootStore().featureStore;
    const { loadAllOption } = useRootStore().optionStore;
    const { loadAllFilteringColor } = useRootStore().filteringColorStore;
    const navigate = useNavigate();
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    });
    const [openSidebar, setOpenSidebar] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        loadUserDetails(navigate).then((loadedUser: IUser) => {
            if (!loadedUser) {
                setLoading(false);
                return;
            }

            if (loadedUser.role === ApplicationRole.StoreAdministrator) {
                loadAdminFrontEndData().finally(() => setLoading(false));
                return;
            }

            if (loadedUser.role === ApplicationRole.MidibiAdministrator) {
                Promise.all([
                    loadAdminFrontEndData(),
                    loadAllFeature(loadedUser.companyId),
                    loadAllOption(loadedUser.companyId),
                    loadAllFilteringColor(),
                ]).finally(() => setLoading(false));
                return;
            }
        });
    }, [loadUserDetails, setLoading]);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const shouldOpenSidebar = isDesktop ? true : openSidebar;

    return (
        <>
            {isLoading || !currentUser ? (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <div
                    className={clsx({
                        [classes.root]: true,
                        [classes.shiftContent]: isDesktop,
                    })}
                >
                    <div>
                        <TopBar onSidebarOpen={handleSidebarOpen} />
                        <NavBar
                            onClose={handleSidebarClose}
                            open={shouldOpenSidebar}
                            pages={pages}
                            variant={isDesktop ? 'persistent' : 'temporary'}
                        />
                        {<main className={classes.content}>{children}</main>}
                    </div>
                </div>
            )}
        </>
    );
};

export default React.memo(observer(MainLayout));
