import { action, makeObservable, observable } from 'mobx';
import { RootStore } from 'mobx/rootStore';
import agent from 'api/agent';
import { IAnalytics } from 'models/analytics';

export default class AnalyticsStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable analytics: IAnalytics | null = null;

    @action loadAnalytics = async (): Promise<void> => {
        this.analytics = await agent.Analytics.getAnalytics();
    };
}
