import { FloorColorLayerName, WallColorLayerName } from 'utils/appConstants';
import { DoorType } from './enums/doorType';

export interface IColorLayer {
    id: string;
    name: string;
    buttonPosX: number;
    buttonPosY: number;
    layerSvg: string;
    featureId: string;
    optionImages: IColorLayerOptionImage[];
    doorSvgs: DoorSvg[];
}

export class ColorLayer implements IColorLayer {
    id: string;
    name: string;
    buttonPosX = 0;
    buttonPosY = 0;
    layerSvg: string;
    featureId: string;
    optionImages: IColorLayerOptionImage[] = [];
    doorSvgs: DoorSvg[] = [];

    constructor(name?: string, featureId?: string) {
        if (name) {
            this.name = name;
        }

        if (featureId) {
            this.featureId = featureId;
        }
    }
}

export const isDefaultColorLayer = (colorLayerName: string): boolean => {
    return colorLayerName === WallColorLayerName || colorLayerName === FloorColorLayerName;
};

export interface IColorLayerOptionImage {
    optionId: string;
    featureId: string;
    colorSampleImageUrl: string;
    rotateX: number;
    rotateY: number;
    rotateZ: number;
    isDisplayableDivian?: boolean;
}

export class ColorLayerOptionImage implements IColorLayerOptionImage {
    constructor(
        optionId: string,
        featureId: string,
        colorSampleImageUrl: string,
        rotateX?: number,
        rotateY?: number,
        rotateZ?: number,
        isDisplayableDivian: boolean = null,
    ) {
        this.optionId = optionId;
        this.featureId = featureId;
        this.colorSampleImageUrl = colorSampleImageUrl;
        this.rotateX = rotateX ?? 0;
        this.rotateY = rotateY ?? 0;
        this.rotateZ = rotateZ ?? 0;
        this.isDisplayableDivian = isDisplayableDivian;
    }

    optionId: string;
    featureId: string;
    colorSampleImageUrl: string;
    rotateX: number;
    rotateY: number;
    rotateZ: number;
    isDisplayableDivian?: boolean = null;
}

export class PatternSize {
    width: number;
    height: number;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
    }
}

export interface IDoorSgv {
    layerSvg: string;
    doorType: DoorType;
}

export class DoorSvg implements IDoorSgv {
    layerSvg: string;
    doorType: DoorType;

    constructor(doorType: DoorType) {
        this.doorType = doorType;
        this.layerSvg = null;
    }
}
