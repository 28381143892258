import agent from 'api/agent';
import { Option, IOption } from 'models/option';
import { EditMode } from 'models/enums/editMode';
import { computed, observable, action, runInAction, makeObservable } from 'mobx';
import { RootStore } from 'mobx/rootStore';

export default class OptionStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable allOption: Map<string, IOption> = new Map();
    @observable selectedOption: IOption | null = null;
    @observable selectedOptionId: string | null = null;
    @observable editMode: EditMode = EditMode.Create;

    @computed get getAllOption(): IOption[] {
        return Array.from(this.allOption.values());
    }

    @action createNewOption = (): void => {
        this.selectedOption = new Option();
    };

    @action setSelectedOptionId = (id: string): void => {
        this.selectedOptionId = id;
    };

    @action setEditMode = (editMode: EditMode): void => {
        this.editMode = editMode;
    };

    @action loadAllOption = async (companyId: string): Promise<void> => {
        const options = await agent.Option.getAllOptionByCompanyId(companyId);
        runInAction(() => {
            this.allOption.clear();
            options.forEach((option: IOption) => {
                this.allOption.set(option.id, option);
            });
        });
    };

    @action loadSelectedOption = async (): Promise<void> => {
        if (!this.selectedOptionId) {
            throw new Error('selectedColorId was undefined!');
        }

        const option = await agent.Option.getOptionById(this.selectedOptionId);
        runInAction(() => {
            this.selectedOption = option;
        });
    };

    @action loadSelectedOptionLocally = (): void => {
        this.selectedOption = this.allOption.get(this.selectedOptionId);
    };

    @action searchOption = async (fragment: string): Promise<void> => {
        const allOption = await agent.Option.searchOption(fragment);
        runInAction(() => {
            this.allOption.clear();
            allOption.forEach((option: IOption) => {
                this.allOption.set(option.id, option);
            });
        });
    };

    deleteOption = async (): Promise<boolean> => {
        if (!this.selectedOptionId) {
            throw new Error('selectedColorId was undefined!');
        }

        const isSucceded = await agent.Option.deleteOption(this.selectedOptionId);
        return isSucceded;
    };

    getLocalOptionById = (optionId: string): IOption => {
        return this.allOption.get(optionId);
    };

    getFeatureOptionsByFeatureId = (featureId: string): IOption[] => {
        const options: IOption[] = [];
        this.rootStore.furnitureStore.selectedFurniture.furnitureFeatureOptions
            .find((x) => x.featureId == featureId)
            .options.map((option) => {
                options.push(this.allOption.get(option.optionId));
            });
        return options.sort((optionA, optionB) => optionA.optionCode.localeCompare(optionB.optionCode));
    };
}
