import { Box, Card, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IAnalytics } from 'models/analytics';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    label: {
        marginLeft: theme.spacing(1),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        height: 48,
        width: 48,
    },
}));

interface IMostViewedFurnituresProps {
    isLoading: boolean;
    analytics: IAnalytics;
}

const MostViewedFurnitures: React.FC<IMostViewedFurnituresProps> = ({ isLoading, analytics }) => {
    const styles = useStyles();

    return (
        <Box mt={3}>
            <Card className={styles.root}>
                <Box width={1}>
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <PerfectScrollbar>
                            <Box minWidth={600}>
                                {isLoading ? (
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ height: '100%' }}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Box>
                                ) : (
                                    <>
                                        {analytics &&
                                            analytics.mostViewedFurniture &&
                                            analytics.mostViewedFurniture.length && (
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Név</TableCell>
                                                            <TableCell>Ár</TableCell>
                                                            <TableCell>Típus</TableCell>
                                                            <TableCell>Kattintások</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {analytics.mostViewedFurniture.map(
                                                            (
                                                                furniture: {
                                                                    name: string;
                                                                    price: number;
                                                                    type: string;
                                                                    numberOfClicks: number;
                                                                },
                                                                index: number,
                                                            ) => {
                                                                return (
                                                                    <TableRow hover key={index}>
                                                                        <TableCell>{furniture.name}</TableCell>
                                                                        <TableCell>
                                                                            {furniture?.price || 0} Ft
                                                                        </TableCell>
                                                                        <TableCell>{furniture.type}</TableCell>
                                                                        <TableCell>
                                                                            {furniture.numberOfClicks}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            },
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            )}
                                    </>
                                )}
                            </Box>
                        </PerfectScrollbar>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

export default MostViewedFurnitures;
