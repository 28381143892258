import { Box, Card, CircularProgress, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IAnalytics } from 'models/analytics';
import React from 'react';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, Tooltip } from 'recharts';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    label: {
        marginLeft: theme.spacing(1),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        height: 48,
        width: 48,
    },
}));

interface IMostViewedFurnitureTypesProps {
    isLoading: boolean;
    analytics: IAnalytics;
}

const MostViewedFurnitureTypes: React.FC<IMostViewedFurnitureTypesProps> = ({ isLoading, analytics }) => {
    const styles = useStyles();

    return (
        <Box mt={3}>
            <Card className={styles.root}>
                <Box flexGrow={1}>
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box>
                            <Typography component="h3" gutterBottom variant="overline" color="textSecondary">
                                Legtöbbet megtekintett bútor típusok
                            </Typography>
                            <Box mt={2}>
                                <ResponsiveContainer width="100%" height={200}>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={analytics?.mostViewedFurnitureTypes || []}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="value" fill="#d1d0cf" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

export default MostViewedFurnitureTypes;
