export enum EditorType {
    None,
    Unique,
    ColorEditor,
    FurnitureEditor,
    AdditionalEquipment,
}

export const EditorTypeToString = (index: any): string => {
    switch (index) {
        case 0:
            return 'Nincs';
        case 1:
            return 'Egyedi';
        case 2:
            return 'Színszerkesztő';
        case 3:
            return 'Bútor szerkesztő';
        case 4:
            return 'Kiegészítő szerkesztő';
    }
};
