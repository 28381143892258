import React from 'react';
import { Avatar, Box, Card, Typography, CircularProgress, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    label: {
        marginLeft: theme.spacing(1),
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        height: 48,
        width: 48,
    },
}));

interface IInformationCardProps {
    title: string;
    value: string;
    icon: React.ReactNode;
    isLoading: boolean;
}

const InformationCard: React.FC<IInformationCardProps> = ({ title, value, icon, isLoading }) => {
    const styles = useStyles();

    return (
        <Card className={styles.root}>
            <Box flexGrow={1}>
                {isLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        <Typography component="h3" gutterBottom variant="overline" color="textSecondary">
                            {title}
                        </Typography>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                            <Typography variant="h3" color="textPrimary">
                                {value}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
            <Avatar className={styles.avatar}>{icon}</Avatar>
        </Card>
    );
};

export default InformationCard;
