import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import agent from 'api/agent';
import { Feature, IFeature } from 'models/feature';
import { EditMode } from 'models/enums/editMode';
import { RootStore } from 'mobx/rootStore';
import { SupportAgentTwoTone } from '@mui/icons-material';

export default class FeatureStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable allFeature: Map<string, IFeature> = new Map();
    @observable selectedFeature: IFeature | null = null;
    @observable selectedFeatureId: string | null = null;
    @observable editMode: EditMode = EditMode.Create;

    @computed get getAllFeature(): IFeature[] {
        return Array.from(this.allFeature.values());
    }

    @action createNewFeature = (): void => {
        this.selectedFeature = new Feature();
    };

    @action setSelectedFeatureId = (id: string): void => {
        this.selectedFeatureId = id;
    };

    @action setEditMode = (editMode: EditMode): void => {
        this.editMode = editMode;
    };

    @action loadAllFeature = async (companyId: string): Promise<void> => {
        const features = await agent.Feature.getAllFeatureByCompanyId(companyId);
        runInAction(() => {
            this.allFeature.clear();
            features.forEach((feature: IFeature) => {
                this.allFeature.set(feature.id, feature);
            });
        });
    };

    @action loadSelectedFeature = async (): Promise<void> => {
        if (!this.selectedFeatureId) {
            throw new Error('selectedColorGroupId was undefined!');
        }

        const feature = await agent.Feature.getFeatureById(this.selectedFeatureId);
        runInAction(() => {
            this.selectedFeature = feature;
        });
    };

    @action searchFeature = async (fragment: string): Promise<void> => {
        const allFeature = await agent.Feature.searchFeature(fragment);
        runInAction(() => {
            this.allFeature.clear();
            allFeature.forEach((feature: IFeature) => {
                this.allFeature.set(feature.id, feature);
            });
        });
    };

    deleteFeature = async (): Promise<boolean> => {
        if (!this.selectedFeatureId) {
            throw new Error('selectedColorGroupId was undefined!');
        }

        const isSucceded = await agent.Feature.deleteFeature(this.selectedFeatureId);
        return isSucceded;
    };

    getLocalFeatureById = (featureId: string): IFeature => {
        return this.allFeature.get(featureId);
    };

    getLocalFeatureByName = (featureName: string): IFeature => {
        return this.getAllFeature?.find((feature) => feature.name === featureName) || null;
    };

    getFurnitureFeatures = (): IFeature[] => {
        const features: IFeature[] = [];
        this.rootStore.furnitureStore.getFurnituresFeatureIds.map((featureId) => {
            features.push(this.allFeature.get(featureId));
        });
        return features
            .filter((feature) => feature.featureCode != 'FC_W' && feature.featureCode != 'FC_F')
            .sort((featureA, featureB) => featureA.featureCode.localeCompare(featureB.featureCode));
    };
}
