import React from 'react';
import clsx from 'clsx';
import { Button, colors, List, ListItem, Theme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useRootStore } from 'mobx/rootStore';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        width: '100%',
    },
    button: {
        color: '#616161',
        // padding: '10px 8px',
        justifyContent: 'flex-start',
        alignItems: 'left',
        textTransform: 'none',
        letterSpacing: 0,
        // width: 'auto',
        // background: colors.grey[400],
        backgroundColor: 'transparent',
    },
    icon: {
        color: colors.grey[700],
        width: 24,
        height: 24,
        marginRight: theme.spacing(1),
    },
    title: {
        color: '#616161',
        textTransform: 'none',
    },
}));

const Nav: React.FC<{
    className: string;
    pages: { id: number; title: string; href: string; icon: any }[];
    onClose: any;
}> = ({ className, pages, onClose }) => {
    const { currentUser } = useRootStore().userStore;
    const styles = useStyles();
    const navigate = useNavigate();

    const navigateTo = (href: string) => {
        navigate(href);
        onClose();
    };

    const isNavItemEnabled = (page: any): boolean => {
        if (!page.roles.includes(currentUser.role)) {
            return false;
        }

        if (page.companies.length === 0) {
            return true;
        }

        return page.companies.includes(currentUser.companyShortenedName);
    };

    return (
        <List className={clsx(styles.root, className)}>
            {pages.map(
                (page: any) =>
                    isNavItemEnabled(page) && (
                        <ListItem className={styles.item} disableGutters key={page.title}>
                            <Button className={styles.button} onClick={() => navigateTo(page.href)}>
                                <div className={styles.icon}>{page.icon}</div>
                                {/* <Typography variant="h6" className={styles.title} gutterBottom component="div">
                                        {page.title}
                                    </Typography> */}
                                <span className={styles.title}>{page.title}</span>
                            </Button>
                        </ListItem>
                    ),
            )}
        </List>
    );
};

export default Nav;
