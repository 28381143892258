import { createContext, useContext } from 'react';
import { configure } from 'mobx';
import UserStore from 'mobx/userStore';
import StoreStore from 'mobx/storeStore';
import FurnitureStore from 'mobx/furnitureStore';
import AnalyticsStore from 'mobx/analyticsStore';
import FrontEndStore from 'mobx/frontEndStore';
import RegionStore from 'mobx/regionStore';
import OptionStore from 'mobx/optionsStore';
import FeatureStore from 'mobx/featureStore';
import FilteringColorStore from 'mobx/filteringColorStore';
import OrderStore from './orderStore';
import FurnitureEditorMiloStore from './furnitureEditorMiloStore';
import FurnitureEditorBiroStore from './furnitureEditorBiroStore';
import FurnitureEditorDivianStore from './furnitureEditorDivianStore';
import MarginStore from './marginStore';
import MidibiFurnitureManagerStore from './midibiFurnitureManagerStore';

configure({ enforceActions: 'always' });

export class RootStore {
    userStore: UserStore;
    analyticsStore: AnalyticsStore;
    storeStore: StoreStore;
    furnitureStore: FurnitureStore;
    frontEndStore: FrontEndStore;
    regionStore: RegionStore;
    optionStore: OptionStore;
    featureStore: FeatureStore;
    filteringColorStore: FilteringColorStore;
    orderStore: OrderStore;
    furnitureEditorMiloStore: FurnitureEditorMiloStore;
    furnitureEditorBiroStore: FurnitureEditorBiroStore;
    furnitureEditorDivianStore: FurnitureEditorDivianStore;
    marginStore: MarginStore;
    midibiFurnitureManagerStore: MidibiFurnitureManagerStore;

    constructor() {
        this.userStore = new UserStore(this);
        this.analyticsStore = new AnalyticsStore(this);
        this.storeStore = new StoreStore(this);
        this.furnitureStore = new FurnitureStore(this);
        this.frontEndStore = new FrontEndStore(this);
        this.regionStore = new RegionStore(this);
        this.optionStore = new OptionStore(this);
        this.featureStore = new FeatureStore(this);
        this.filteringColorStore = new FilteringColorStore(this);
        this.orderStore = new OrderStore(this);
        this.furnitureEditorMiloStore = new FurnitureEditorMiloStore(this);
        this.furnitureEditorBiroStore = new FurnitureEditorBiroStore(this);
        this.furnitureEditorDivianStore = new FurnitureEditorDivianStore(this);
        this.marginStore = new MarginStore(this);
        this.midibiFurnitureManagerStore = new MidibiFurnitureManagerStore(this);
    }
}

const rootStore = new RootStore();
export const RootStoreContext = createContext(rootStore);

export function useRootStore(): RootStore {
    return useContext(RootStoreContext);
}
