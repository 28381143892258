import agent from 'api/agent';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { ColorLayer, ColorLayerOptionImage, IColorLayer, IColorLayerOptionImage } from 'models/colorLayer';
import { IFurniture } from 'models/furniture';
import { BiroColorEditor, ColorCombination, IBiroColorEditor } from 'models/furnitureEditor';
import { RootStore } from './rootStore';

export default class FurnitureEditorBiroStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;
    }

    @observable selectedBiroFurniture: IFurniture | null = null;
    @observable isEditedColorCombinations = false;

    @computed get getIsEditedColorCombinations(): boolean {
        return this.isEditedColorCombinations;
    }

    @computed get getIsColorCombinationEnabled(): boolean {
        return this.selectedBiroFurniture.biroColorEditor?.isColorCombinationEnabled;
    }

    @computed get getSelectedBiroFurniture(): IFurniture {
        return this.selectedBiroFurniture;
    }

    @computed get getBiroColorEditor(): IBiroColorEditor {
        return this.selectedBiroFurniture?.biroColorEditor;
    }

    @action clearSelectedBiroFurniture = async (): Promise<void> => {
        this.selectedBiroFurniture = null;
    };

    @action loadSelectedBiroFurniture = async (furnitureId: string): Promise<void> => {
        if (!furnitureId) {
            throw new Error('furnitureId was undefined!');
        }

        this.selectedBiroFurniture = await agent.FurnitureEditor.getFurnitureWithBiroColorEditorById(furnitureId);
    };

    @action setIsColorCombinationEnabled = (isColorCombinationEnabled: boolean): void => {
        this.selectedBiroFurniture.biroColorEditor.isColorCombinationEnabled = isColorCombinationEnabled;
    };

    @action setColorEditorBaseImage = (imageUrl: string): void => {
        if (imageUrl) this.selectedBiroFurniture.biroColorEditor.colorEditorBaseImgUrl = imageUrl;
    };

    @action deleteColorEditorBaseImage = (): void => {
        this.selectedBiroFurniture.biroColorEditor.colorEditorBaseImgUrl = null;

        // Force UI render
        this.selectedBiroFurniture = { ...this.selectedBiroFurniture };
    };

    @action initialColorEditorBiro = (): void => {
        if (this.selectedBiroFurniture.biroColorEditor == null) {
            const biroColorEditor = new BiroColorEditor();
            biroColorEditor.colorLayers = this.getColorLayersForFurniture();
            biroColorEditor.colorLayers.map((layer: IColorLayer) => {
                const optionIds = this.getOptionsIdsByFeature(layer.featureId);
                optionIds.map((optionId) => {
                    layer.optionImages.push(new ColorLayerOptionImage(optionId, layer.featureId, null));
                });
            });
            this.selectedBiroFurniture.biroColorEditor = biroColorEditor;
            this.selectedBiroFurniture.biroColorEditor.isColorCombinationEnabled = false;
        } else {
            const freshColorLayers = this.getColorLayersForFurniture();
            freshColorLayers.map((freshLayer: IColorLayer) => {
                const colorLayer = this.selectedBiroFurniture.biroColorEditor.colorLayers.find(
                    (x) => x.featureId == freshLayer.featureId,
                );
                const optionIds = this.getOptionsIdsByFeature(freshLayer.featureId);
                if (colorLayer) {
                    optionIds.map((optionId) => {
                        if (colorLayer.optionImages.findIndex((x) => x.optionId == optionId) < 0)
                            colorLayer.optionImages.push(
                                new ColorLayerOptionImage(optionId, freshLayer.featureId, null),
                            );
                    });

                    const toBeDeletedOptionImageIndexes = [];
                    colorLayer.optionImages.map((optionImage: IColorLayerOptionImage, index: number) => {
                        if (!optionIds.includes(optionImage.optionId)) toBeDeletedOptionImageIndexes.push(index);
                    });
                    toBeDeletedOptionImageIndexes.map((idx) => {
                        colorLayer.optionImages.splice(idx, 1);
                    });
                } else {
                    optionIds.map((optionId) => {
                        freshLayer.optionImages.push(new ColorLayerOptionImage(optionId, freshLayer.featureId, null));
                    });
                    this.selectedBiroFurniture.biroColorEditor.colorLayers.push(freshLayer);
                }
            });

            const toBeDeletedColorLayerIndexes = [];
            this.selectedBiroFurniture.biroColorEditor.colorLayers.map((colorLayer: IColorLayer) => {
                const idx = freshColorLayers.findIndex((x) => x.featureId == colorLayer.featureId);
                if (idx < 0) toBeDeletedColorLayerIndexes.push(idx);
            });
            toBeDeletedColorLayerIndexes.map((idx) => {
                this.selectedBiroFurniture.biroColorEditor.colorLayers.splice(idx, 1);
            });
        }

        if (!this.selectedBiroFurniture.biroColorEditor.colorCombinations)
            this.selectedBiroFurniture.biroColorEditor.colorCombinations = [];
    };

    @action setButtonPosXOnColorLayer = (colorLayerName: string, buttonPosX: number): void => {
        this.selectedBiroFurniture.biroColorEditor.colorLayers.find((cl) => cl.name === colorLayerName).buttonPosX =
            buttonPosX;
    };

    @action setButtonPosYOnColorLayer = (colorLayerName: string, buttonPosY: number): void => {
        this.selectedBiroFurniture.biroColorEditor.colorLayers.find((cl) => cl.name === colorLayerName).buttonPosY =
            buttonPosY;
    };

    @action setSvgData = (colorLayerName: string, svgData: string): void => {
        const colorLayer = this.selectedBiroFurniture.biroColorEditor.colorLayers.find(
            (cl) => cl.name === colorLayerName,
        );

        colorLayer.layerSvg = svgData;

        // Force UI render
        this.selectedBiroFurniture = { ...this.selectedBiroFurniture };
    };

    @action setColorLayerOptionImage = (layer: IColorLayer, optionId: string, colorSampleUrl: string): void => {
        const colorLayer = this.selectedBiroFurniture.biroColorEditor.colorLayers?.find((cl) => cl.name === layer.name);

        const optionImage = colorLayer?.optionImages?.find(
            (oi) => oi.optionId === optionId && oi.featureId === colorLayer.featureId,
        );

        if (!optionImage) {
            if (!colorLayer?.optionImages) {
                colorLayer.optionImages = [];
            }

            colorLayer.optionImages.push(new ColorLayerOptionImage(optionId, colorLayer.featureId, colorSampleUrl));
            return;
        }

        optionImage.colorSampleImageUrl = colorSampleUrl;
    };

    @action setOptionImageRotation = (
        layer: IColorLayer,
        optionId: string,
        rotateX: string,
        rotateY: string,
        rotateZ: string,
    ): void => {
        const colorLayer = this.selectedBiroFurniture.biroColorEditor.colorLayers?.find((cl) => cl.name === layer.name);

        const optionImage = colorLayer?.optionImages?.find(
            (oi) => oi.optionId === optionId && oi.featureId === colorLayer.featureId,
        );

        optionImage.rotateX = parseInt(rotateX);
        optionImage.rotateY = parseInt(rotateY);
        optionImage.rotateZ = parseInt(rotateZ);
    };

    @action removeColorCombination = (colorCombination: ColorCombination): void => {
        const idx = this.selectedBiroFurniture.biroColorEditor.colorCombinations.findIndex((x) =>
            this.objectsEqual(x.colorCombinationItems, colorCombination.colorCombinationItems),
        );
        if (idx > -1) this.selectedBiroFurniture.biroColorEditor.colorCombinations.splice(idx, 1);

        // Force UI render
        this.isEditedColorCombinations = !this.isEditedColorCombinations;
    };

    @action addColorCombination = (colorCombination: ColorCombination): void => {
        if (!colorCombination) return;

        this.selectedBiroFurniture.biroColorEditor.colorCombinations.push(colorCombination);

        // Force UI render
        this.isEditedColorCombinations = !this.isEditedColorCombinations;
    };

    getColorLayersForFurniture = (): IColorLayer[] => {
        return [
            ...this.selectedBiroFurniture.furnitureFeatureOptions.map((ffo) => {
                const feature = this.rootStore.featureStore.allFeature.get(ffo.featureId);
                return new ColorLayer(feature.name, feature.id);
            }),
        ];
    };

    getImageByOptionFromColorLayer = (colorLayerName: string, optionId: string): string => {
        const colorLayer = this.getBiroColorEditor.colorLayers.find((cl) => cl.name === colorLayerName);

        if (!colorLayer) {
            throw new Error('colorLayer was null!');
        }

        return colorLayer.optionImages?.find((oi) => oi.optionId === optionId)?.colorSampleImageUrl;
    };

    getOptionsIdsByFeature = (featureId: string): string[] => {
        return this.selectedBiroFurniture.furnitureFeatureOptions
            ?.find((ffo) => ffo.featureId == featureId)
            ?.options.map((optionImage) => optionImage.optionId);
    };

    getColorLayer = (colorLayerName: string): IColorLayer => {
        return this.selectedBiroFurniture.biroColorEditor.colorLayers.find((cl) => cl.name === colorLayerName);
    };

    objectsEqual = (o1: any, o2: any) =>
        Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => o1[p] === o2[p]);
}
