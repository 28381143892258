import { action, makeObservable, observable } from 'mobx';
import agent from 'api/agent';
import { IAdminFrontEndData } from 'models/frontEnd';
import { RootStore } from 'mobx/rootStore';
import { FurnitureType } from 'models/enums/furnitureType';

export default class FrontEndStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable adminFrontEndData: IAdminFrontEndData | null = null;

    @action loadAdminFrontEndData = async (): Promise<IAdminFrontEndData> => {
        const adminFrontEndData = await agent.AdminFrontEnd.getAdminFrontEndData();
        this.adminFrontEndData = adminFrontEndData;
        return adminFrontEndData;
    };

    getCurrentUsersCompanyFurnitureTypes(
        adminFrontEndData: IAdminFrontEndData,
        companyId: string,
    ): { furnitureType: FurnitureType; furnitureTypeInString: string }[] {
        const result: { furnitureType: FurnitureType; furnitureTypeInString: string }[] = [];

        if (companyId === 'all') {
            Object.values(adminFrontEndData.furnitureTypesByCompanies).forEach((companyIdWithTypes) => {
                Object.keys(companyIdWithTypes).forEach((type) => {
                    if (!result.some((x) => x.furnitureType == FurnitureType[type])) {
                        result.push({
                            furnitureType: FurnitureType[type],
                            furnitureTypeInString: companyIdWithTypes[type],
                        });
                    }
                });
            });
        } else {
            const currentUsersCompanyFurnitureTypes = adminFrontEndData.furnitureTypesByCompanies[companyId];
            Object.keys(currentUsersCompanyFurnitureTypes).forEach((type) => {
                result.push({
                    furnitureType: FurnitureType[type],
                    furnitureTypeInString: currentUsersCompanyFurnitureTypes[type],
                });
            });
        }

        return result.sort((a, b) => a.furnitureTypeInString.localeCompare(b.furnitureTypeInString));
    }
}
