import { action, makeObservable, observable, computed } from 'mobx';
import { RootStore } from 'mobx/rootStore';
import agent from 'api/agent';
import { IMinimumMargin } from 'models/margin';

export default class MarginStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @observable minimumMargins: IMinimumMargin[] = [];

    @computed get getMinimumMargins(): IMinimumMargin[] {
        return this.minimumMargins;
    }

    @action loadMinimumMargins = async (companyId: string): Promise<IMinimumMargin[]> => {
        const minimumMargins = await agent.Company.getAllMinimumMarginByCompanyId(companyId);
        minimumMargins.sort((a, b) => a.furnitureTypeInString.localeCompare(b.furnitureTypeInString));
        this.minimumMargins = minimumMargins;
        return minimumMargins;
    };
}
