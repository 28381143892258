import React, { useContext } from 'react';
import InformationCard from '../InformationalCard';
import { DeckOutlined, StoreOutlined } from '@mui/icons-material';
import { RootStoreContext } from 'mobx/rootStore';
import { IAnalytics } from 'models/analytics';
import { ApplicationRole } from 'models/enums/applicationRole';
import { Grid } from '@mui/material';

interface IAnalyticsProps {
    isLoading: boolean;
    analytics: IAnalytics;
}

const Analytics: React.FC<IAnalyticsProps> = ({ isLoading, analytics }) => {
    const { currentUser } = useContext(RootStoreContext).userStore;

    return (
        <Grid container spacing={3}>
            {currentUser.role === ApplicationRole.MidibiAdministrator && (
                <>
                    <Grid item lg={3} sm={6} xs={12}>
                        <InformationCard
                            title="Bútorok száma"
                            value={`${analytics?.numberOfFurniture || 0} db`}
                            icon={<DeckOutlined />}
                            isLoading={isLoading}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                        <InformationCard
                            title="Üzletek száma"
                            value={`${analytics?.numberOfStores || 0} db`}
                            icon={<StoreOutlined />}
                            isLoading={isLoading}
                        />
                    </Grid>
                </>
            )}
            {currentUser.role === ApplicationRole.StoreAdministrator && (
                <>
                    {analytics?.numberOfMiloFurniture && (
                        <Grid item lg={3} sm={6} xs={12}>
                            <InformationCard
                                title="Miló bútorok"
                                value={`${analytics?.numberOfMiloFurniture} db`}
                                icon={<DeckOutlined />}
                                isLoading={isLoading}
                            />
                        </Grid>
                    )}
                    {analytics?.numberOfDivianFurniture && (
                        <Grid item lg={3} sm={6} xs={12}>
                            <InformationCard
                                title="Divian bútorok"
                                value={`${analytics?.numberOfDivianFurniture} db`}
                                icon={<DeckOutlined />}
                                isLoading={isLoading}
                            />
                        </Grid>
                    )}
                    {analytics?.numberOfBiroFurniture && (
                        <Grid item lg={3} sm={6} xs={12}>
                            <InformationCard
                                title="Bíró bútorok"
                                value={`${analytics?.numberOfBiroFurniture} db`}
                                icon={<DeckOutlined />}
                                isLoading={isLoading}
                            />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

export default Analytics;
