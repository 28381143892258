import { EditorType } from './enums/editorType';

export interface IFeature {
    id: string;
    name: string;
    featureCode: string;
    isShowableOnFrontend: boolean;
    sequence: number;
    displayName: string;
    editorType?: EditorType;
    createdAt: string;
    createdBy: string;
    lastModifiedAt: string;
    lastModifiedBy: string;
}

export class Feature implements IFeature {
    id!: string;
    name!: string;
    featureCode!: string;
    isShowableOnFrontend!: boolean;
    sequence: number;
    displayName: string;
    editorType?: EditorType;
    createdAt!: string;
    createdBy!: string;
    lastModifiedAt!: string;
    lastModifiedBy!: string;
}
